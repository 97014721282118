import { Link, Stack, Typography } from '@mui/material';
import LaunchAppButton from 'components/LaunchAppButton';
import PopUp from 'coreComponents/PopUp/PopUp';
import { ConfigContext } from 'context';
import InstavisionButton from 'coreComponents/Button/Button';
import { AppBridge } from 'infrastructure/appBridge/appBridge';
import { APP_BRIDGE_CALLBACKS } from 'infrastructure/appBridge/appCallBacks';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PopupTypes } from 'typings/popUpTypes';
import { getPartnerId } from 'utils/commonFunctions';
import { isRunningInWebView } from 'utils/webviewHelpers';
import { PopupIllustrationImage } from '../popupIllustrationStyle';

function PaymentFailedPopup() {
  const [isPopUpOpen, setIsPopUpOpen] = useState(true);
  const config = useContext(ConfigContext);
  const PaymentFailedIcon = config.paymentFailed;
  const partnerName = getPartnerId();

  const { t } = useTranslation('translation', {
    keyPrefix: 'components.paymentFailedPopup'
  });

  const isWebViewPresent = isRunningInWebView();

  const supportEmail =
    partnerName === 'instaview' ? config.supportEmail : config.supportEmail2;

  const handlePopUpBackButtonClick = () => {
    setIsPopUpOpen(false);
    AppBridge.sendMessageToApp(APP_BRIDGE_CALLBACKS.CLOSE_IN_APP_WEBVIEW);
  };

  return (
    <PopUp isOpen={isPopUpOpen} type={PopupTypes.TYPE2}>
      <>
        <Stack direction="column" alignItems="center" justifyContent="center">
          <PopupIllustrationImage
            src={PaymentFailedIcon}
            alt="payment-failed"
          />
          <Typography variant="h2" paddingBottom="12px">
            {t('paymentFailedTitle')}
          </Typography>
        </Stack>
        <Stack marginBottom="12px" justifyContent="center" alignItems="center">
          <Typography
            variant="Body1Medium"
            sx={{ marginBottom: '2px', textAlign: 'center' }}
          >
            {t('contactSupportText')}
          </Typography>
          <Typography variant="Body1Medium" sx={{ marginBottom: '2px' }}>
            {t('email')} <Link href={supportEmail}>{supportEmail}</Link>
          </Typography>
          <Typography variant="Body1Medium" sx={{ marginBottom: '2px' }}>
            {t('phoneNumber')} {config.phoneNumber}{' '}
          </Typography>
        </Stack>
        <Stack direction="column" alignItems="Stretch" marginBottom="6px">
          {isWebViewPresent ? (
            <InstavisionButton
              sx={{
                marginBottom: '16px',
                fontSize: (theme) => theme.typography.h3.fontSize,
                fontWeight: (theme) => theme.typography.h3.fontWeight,
                lineHeight: (theme) => theme.typography.h3.lineHeight
              }}
              onClick={() => handlePopUpBackButtonClick()}
            >
              {t('okay')}
            </InstavisionButton>
          ) : (
            <LaunchAppButton />
          )}
        </Stack>
      </>
    </PopUp>
  );
}
export default PaymentFailedPopup;
