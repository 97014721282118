import Clarity from '@microsoft/clarity';
import envConfig from 'configs/envConfig';

const projectId = envConfig('microsoftClarityProjectId');

export const enableClarity =
  envConfig('env') === 'prod' ||
  envConfig('env') === 'staging' ||
  envConfig('env') === 'luna-stg' ||
  envConfig('env') === 'luna';

if (projectId && enableClarity) Clarity.init(projectId);
