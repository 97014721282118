import { Stack, Typography } from '@mui/material';
import InternetNotConnectedIllustration from 'assets/interntNotConnected.svg';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  APP_BUTTON_TYPES,
  isRunningInWebView,
  showAppButton
} from 'utils/webviewHelpers';

function InternetNotConnected() {
  const isWebViewPresent = isRunningInWebView();
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.internetNotConnected'
  });

  useEffect(() => {
    if (isRunningInWebView()) {
      showAppButton(APP_BUTTON_TYPES.CLOSE);
    }
  }, [isRunningInWebView]);

  return (
    <Stack
      position="absolute"
      justifyContent="center"
      alignItems="center"
      height={isWebViewPresent ? '100vh' : '80%'}
      maxHeight="100vh"
      gap={2}
    >
      <img
        src={InternetNotConnectedIllustration}
        alt="internet-not-connected"
        style={{ objectFit: 'cover' }}
      />
      <Typography textAlign="center" fontWeight={600}>
        {t('recheckInternetText')}
      </Typography>
    </Stack>
  );
}

export default InternetNotConnected;
