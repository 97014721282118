/* eslint-disable no-param-reassign */
import axios, { AxiosInstance } from 'axios';
import { auth } from 'configs/firebaseConfig';
import urls from 'configs/urls';
import { AppBridge } from 'infrastructure/appBridge/appBridge';
import { APP_BRIDGE_CALLBACKS } from 'infrastructure/appBridge/appCallBacks';
import { getPartnerName } from 'partner_configuration';
import { LogoutUser } from 'store/store';
import {
  getLocalStorageItem,
  isTokenExpiringSoon,
  setLocalStorageItem,
  setRequestHeaders
} from 'utils/commonFunctions';
import {
  clearSession,
  getSessionStorageItem,
  refreshSession,
  setSessionStorageItem
} from 'utils/sessionFunctions';
import {
  fetchWebViewAccessToken,
  isRunningInWebView
} from 'utils/webviewHelpers';
import { v4 as uuidv4 } from 'uuid';

/**
 * Create multiple axios instances with different base URLs for easier
 * communication with different services.
 * Base URLs are dependent on current environment.
 * */

const homeSecurityPortalApi: AxiosInstance = axios.create({
  baseURL: urls.baseUrl,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const returnNewToken = async (): Promise<string> => {
  const user = auth.currentUser;

  if (user) {
    let attempts = 0;
    const maxAttempts = 2;
    while (attempts < maxAttempts) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const newToken = await user?.getIdToken(true);
        return newToken;
      } catch (error) {
        attempts += 1;
        console.error(`Attempt ${attempts} to fetch new token failed:`, error);
        if (attempts >= maxAttempts) {
          throw new Error('Failed to refresh token after multiple attempts');
        }
      }
    }
  } else {
    // if no user logged out the user
    LogoutUser();
  }
  throw new Error('Unexpected error in token refresh logic.');
};

let tokenRefreshPromise: Promise<string | null> | null = null;

homeSecurityPortalApi.interceptors.request.use(async (request) => {
  if (request && request.headers && !request.headers['skip-auth']) {
    let token = getLocalStorageItem('auth-token');

    if (token && isTokenExpiringSoon(token)) {
      if (!isRunningInWebView()) {
        if (!tokenRefreshPromise) {
          // Start token refresh
          tokenRefreshPromise = returnNewToken()
            .then((newToken) => {
              if (newToken) {
                setLocalStorageItem('auth-token', newToken);
                return newToken;
              }
              return null;
            })
            .catch((error) => {
              console.error('Error refreshing token:', error);
              throw error;
            })
            .finally(() => {
              // Clear the refresh promise once completed
              tokenRefreshPromise = null;
            });
        }

        // Wait for the token refresh to complete
        token = await tokenRefreshPromise;
      } else {
        // If running in webview, get the token from the app
        // AppBridge.sendMessageToAppWithReturn(
        //   APP_BRIDGE_CALLBACKS.GET_ACCESS_TOKEN,
        //   {
        //     needRefresh: true
        //   }
        // ).then((res) => {
        //   const data = JSON.parse(JSON.stringify(res));
        //   token = data.accessToken;
        //   setRequestHeaders(data.accessToken);
        //   setLocalStorageItem('auth-token', data.accessToken);
        // });
        fetchWebViewAccessToken(true).then((res) => {
          token = res;
          setRequestHeaders(res);
          setLocalStorageItem('auth-token', res);
        });
      }
    }

    if (token) {
      request.headers['Authorization'] = `Bearer ${token}`;
    }
  }

  const url = window.location.origin;
  const partnerName = getPartnerName(url);
  request.baseURL = urls.baseUrl;
  request.headers['Partner-ID'] = partnerName;
  request.headers['Client-ID'] = 'home-user-portal';

  // Handle session ID
  let sessionID = getSessionStorageItem('session-id');
  if (!sessionID) {
    sessionID = refreshSession();
    setSessionStorageItem('session-id', sessionID);
  }
  request.headers['X-Session-ID'] = sessionID;
  request.headers['X-Request-ID'] = uuidv4();

  return request;
});

homeSecurityPortalApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    // const originalRequest = error.config;

    // Check if error.response exists before accessing its properties
    if (error.response) {
      // if (error.response.status === 401 && !originalRequest._retry) {
      //   originalRequest._retry = true;
      //   // Add refresh token logic here
      //   // Example: await refreshAccessToken();
      //   // for now we logging out user when recieve 401 or 403 status code
      //   // clearSession();
      //   // LogoutUser();
      // }

      // if (error.response.status === 403) {
      //   console.error('403 Forbidden error:', error.response.data);
      //   // Handle forbidden access case here
      //   // clearSession();
      //   // LogoutUser();
      // }
      if (error.response.status === 401 || error.response.status === 403) {
        console.log('errInterceptor perform logout');
        if (isRunningInWebView()) {
          clearSession();
          try {
            AppBridge.sendMessageToApp(
              APP_BRIDGE_CALLBACKS.CLOSE_IN_APP_WEBVIEW
            );
          } catch (err) {
            console.error('Error in closing Webview', err);
          }
        } else {
          LogoutUser();
          clearSession();
        }
      }
    } else {
      console.error('Error: ', error.message);
    }

    return Promise.reject(error); // Reject with error to maintain promise chain
  }
);

export { homeSecurityPortalApi };
