// eslint-disable-next-line import/prefer-default-export
export const endpoints = {
  subscriptionUrl: '/atlas/subscriptions',
  subscriptionBuyUrl: '/piggybank/subscriptions',
  subscriptionUpgradeUrl: '/piggybank/subscriptions/upgrade',
  createPurchaseIdUrl: '/piggybank/purchases',
  checkTrialUrl: '/piggybank/purchases/trial',
  skipFreeTrial: '/piggybank/purchases/trial/skip-reason-list',
  userSkipTrial: '/piggybank/purchases/trial/user-skip-reason',
  paymentMethodsUrl: '/piggybank/payment_gateway/payment_methods',
  setupIntentUrl: '/piggybank/payment_gateway/setup_intents',
  plansUrl: 'piggybank/products/skus?country=US&product_ids=1,2,3',
  getUser: 'atlas/users',
  deleteUser: 'atlas/users',
  getAllSpaces: 'atlas/spaces',
  verifyToken: '/atlas/users/one-time-token/login',
  requestAccountDelete: 'atlas/users/initiate-user-delete',
  oneTimeToken: 'atlas/users/one-time-token',
  checkoutSession: '/piggybank/subscriptions/checkout/session',
  getTransactionsUrl: `/piggybank/purchases/transactions`,
  addDevice: (subscriptionId: string) => {
    return `atlas/subscriptions/${subscriptionId}/devices`;
  },
  addCameraToSubscription: (spaceId: string, deviceId: string) => {
    return `atlas/spaces/${spaceId}/devices/${deviceId}`;
  },
  authenticateDevice: (spaceId: string, deviceId: string) => {
    return `atlas/spaces/${spaceId}/devices/${deviceId}`;
  },
  billings: (transaction_id: string) => {
    return `/piggybank/purchases/transactions/${transaction_id}/invoice`;
  },
  paymentOptions: (purchaseId: string) => {
    return `/piggybank/subscriptions/${purchaseId}/prorate_charges`;
  },
  // getTransactionsUrl: (noOfRecords: number) => {
  //   return `/piggybank/purchases/transactions?skip=${noOfRecords}&limit=10`;
  // },

  getAllDeviceBySpaceIdUrl: (spaceId: string) => {
    return `atlas/spaces/${spaceId}/devices`;
  },
  getCurrentPlanDetails: (skuId: string) => {
    return `piggybank/skus/${skuId}?country=US `;
  },
  changePaymentMethodUrl: (subscriptionId: string) => {
    return `piggybank/subscriptions/${subscriptionId}/payment-method`;
  },
  deletePaymentMethodUrl: (paymentMethodId: string) => {
    return `piggybank/payment_gateway/payment_methods/${paymentMethodId}`;
  },
  updateDefaultPaymentMethodUrl: (paymentMethodId: string) => {
    return `piggybank/payment_gateway/payment_methods/${paymentMethodId}/default`;
  },
  getAllMarketplaces: (spaceId: string, deviceId: string) => {
    return `atlas/spaces/${spaceId}/devices/${deviceId}/marketplace`;
  },
  addCameraPurchaseInfoRequest: (spaceId: string, deviceId: string) => {
    return `atlas/spaces/${spaceId}/devices/${deviceId}/marketplace`;
  }
};
