import { CSSProperties } from 'react';

function PromoRibbon({
  styles,
  label = 'PRIME'
}: {
  styles: CSSProperties;
  label: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="59"
      height="63"
      fill="none"
      viewBox="0 0 59 63"
      style={styles}
    >
      <path
        fill="url(#paint0_linear_34166_9104)"
        d="M45.598 11.441C55.401 1.301 59 .098 59 .098c-8.47-.295-32.9.17-32.9.17-3.08-.126-4.895 1.604-4.895 1.604-3.045 2.6-16.116 17.09-18.418 19.404C.142 23.94.393 25.017.393 25.017-.204 26.651.096 63 .096 63s.788-4.896 9.917-14.6c0 0 23.839-25.103 35.585-36.959"
      />
      <text
        x="50%"
        y="30%"
        textAnchor="middle"
        dominantBaseline="middle"
        fill="white"
        fontSize="12"
        fontWeight="bold"
        transform="rotate(-50, 29.5, 31.5)"
      >
        {label}
      </text>
      <defs>
        <linearGradient
          id="paint0_linear_34166_9104"
          x1="-4.294"
          x2="44.486"
          y1="48.907"
          y2="0.269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8B191A" />
          <stop offset="0.176" stopColor="#F1282A" />
          <stop offset="0.295" stopColor="#F1282A" />
          <stop offset="0.475" stopColor="#F1282A" />
          <stop offset="0.665" stopColor="#F3282A" />
          <stop offset="0.87" stopColor="#F1282A" />
          <stop offset="1" stopColor="#8B191B" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default PromoRibbon;
