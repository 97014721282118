/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable object-shorthand */

import { Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { endpoints } from 'services/api/endpoints';
import { getPlanDetails } from 'services/api/plansApi';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { TPlanList, TPurchasePayload, TSubscriptionType } from 'typings';
import PlanComparisionComponent from 'components/PlanComparisionScreen/PlanComparisionComponent';
import { homeSecurityPortalApi } from 'data/axios';
import { useTranslation } from 'react-i18next';
import {
  fetchCurrentPlanDetails,
  getSubscriptionDetails
} from 'services/api/subscriptionApi';
import { setSelectedSubscriptionInfo } from 'store/reducers/subscriptionReducer';
import {
  APP_BUTTON_TYPES,
  isRunningInWebView,
  showAppButton
} from 'utils/webviewHelpers';
import PlanPageLoader from 'components/PlanPageLoader/PlanPageLoader';

function UpgradeSubscription() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.subscription.upgradeSubscription'
  });

  const [isLoading, setIsLoading] = useState(true);
  const [allPlans, setAllPlans] = useState<TPlanList[]>([]);
  const [currentSubscription, setCurrentSubscription] =
    useState<TSubscriptionType>();
  const [selectedCyclesPlans, setSelectedCyclesPlans] = useState<TPlanList[]>(
    []
  );
  const [eligibleForFreeTrial, setEligibleForFreeTrial] = useState(false);

  const spaceData = useAppSelector((state) => state.space.spaceData);

  const { id: subscriptionId } = useParams();

  const [currentPlanLevel, setCurrentPlanLevel] = useState<number>(0);
  const [currentSubscriptionCycle, setCurrentSubscriptionCycle] =
    useState<number>(1);

  const { deviceId } = (() => {
    const sourceData = location.state || {};

    return {
      deviceId: sourceData?.deviceId || null
    };
  })();

  const filterByLevel = (el: TPlanList) => {
    /*
      If All plans (current plan) subscription cycle is greater then current subscription cycle
     */
    if (el.subscription_cycle > currentSubscriptionCycle) {
      /*
        Return true if All plans (current plan) is yearly then we check for all plans current level is greater than or equal to current plan level
       */
      if (el.level >= currentPlanLevel) {
        return true;
      }
      /*
        Return true if All plans (current plan) is yearly then we check for all plans current level is lesser than to current plan level
       */
      if (el.level < currentPlanLevel) {
        return false;
      }
    }
    if (el.subscription_cycle === currentSubscriptionCycle) {
      if (el.level > currentPlanLevel) {
        return true;
      }
      return false;
    }
    return false;
  };

  const filterPlansForCycle = (
    isYearlyCycle: boolean,
    planList: TPlanList[]
  ) => {
    const cycleMonths = isYearlyCycle ? 12 : 1;
    const cyclePlans = planList.filter(
      (el: TPlanList) => el.subscription_cycle === cycleMonths
    );
    // debugger
    cyclePlans.sort(
      (a, b) => b.prices[0].original_price - a.prices[0].original_price
    );
    setSelectedCyclesPlans(cyclePlans);
  };

  useEffect(() => {
    // console.log('inside useEffect');
    console.log('space Data and Loading', spaceData, isLoading);
    console.log('subscriptionId', subscriptionId);
    if (spaceData[0]?.id) {
      // console.log('inside if condition');
      // debugger
      // dispatch(fetchAllSubscription());
      Promise.all([getPlanDetails(), getSubscriptionDetails()])
        .then((res) => {
          const [allPlansResponse, subscriptionDetails] = res;
          const existingSubscripiton = subscriptionDetails.data.find(
            (subscriptionDetail) => {
              return subscriptionDetail.id === subscriptionId;
            }
          );
          // debugger;
          setEligibleForFreeTrial(subscriptionDetails?.data[0]?.trial);
          console.log('subscriptionDetails filtered', existingSubscripiton);
          if (existingSubscripiton?.sku_id) {
            fetchCurrentPlanDetails(existingSubscripiton?.sku_id).then(
              (planInformation) => {
                setCurrentPlanLevel(planInformation.data.level);
                setCurrentSubscriptionCycle(
                  planInformation.data.subscription_cycle
                );
              }
            );
          }
          if (allPlansResponse.data.length) {
            filterPlansForCycle(true, allPlansResponse.data);
          }
          setAllPlans(allPlansResponse.data);
          if (existingSubscripiton) {
            setCurrentSubscription(existingSubscripiton);
          }
        })
        .catch((err) => {
          // debugger;
          console.log('err', err);
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [spaceData, dispatch, subscriptionId]);

  const createPurchaseId = (plan: TPlanList) => {
    // debugger;
    const payload: TPurchasePayload = {
      country: 'US',
      sku_id: plan.id,
      trial: eligibleForFreeTrial,
      space_id: spaceData[0]?.id,
      action: 'Upgrade',
      previous_purchase_id: currentSubscription?.id
    };

    homeSecurityPortalApi
      .post(endpoints.createPurchaseIdUrl, payload)
      .then((res) => {
        dispatch(
          setSelectedSubscriptionInfo({
            isUpgrade: true,
            isFreeTrial: eligibleForFreeTrial,
            planSelected: plan,
            purchaseId: res.data.purchase_id,
            deviceId,
            previousSubscriptionSKU: currentSubscription?.sku_id
          })
        );
        navigate('/reviewPurchase');
      })
      .catch((err) => console.log('purchases err', err));
  };

  const handlePlanSelection = (el: TPlanList) => {
    createPurchaseId(el);
  };

  useEffect(() => {
    if (isRunningInWebView()) {
      showAppButton(APP_BUTTON_TYPES.RELOAD, APP_BUTTON_TYPES.CLOSE);
    }
  }, [isRunningInWebView]);

  if (isLoading) {
    return <PlanPageLoader canBuyPlan />;
  }

  if (!isLoading && selectedCyclesPlans.length) {
    return (
      <Stack>
        {currentSubscription?.trial && (
          <Typography
            variant="h2"
            sx={{ textTransform: 'capitalize', textAlign: 'center' }}
          >
            {t('chooseFreeTrialPlan')}
          </Typography>
        )}
        {!currentSubscription?.trial && (
          <Typography
            variant="h2"
            sx={{ textTransform: 'capitalize', textAlign: 'center' }}
          >
            {t('upgradePlan')}
          </Typography>
        )}

        {!isLoading &&
          allPlans.length > 0 &&
          selectedCyclesPlans.length > 0 && (
            <PlanComparisionComponent
              plansData={selectedCyclesPlans}
              allPlans={allPlans}
              allowSelection
              onPlanSelection={handlePlanSelection}
              filterByLevel={filterByLevel}
              isUpgrade
              onChange={filterPlansForCycle}
            />
          )}
      </Stack>
    );
  }
  return null;
}

export default UpgradeSubscription;
