import { EventProperties } from './types';

// Standard event names used throughout the application
export enum AppEvent {
  USER_LOGIN = 'USER_LOGIN',
  SCREEN_VIEW_LOGIN_PAGE = 'SCREEN_VIEW_LOGIN_PAGE',
  SCREEN_VIEW_AUTHORIZING_PAGE = 'SCREEN_VIEW_AUTHORIZING_PAGE',
  SCREEN_VIEW_CAMERA_INFO = 'SCREEN_VIEW_CAMERA_INFO',
  SCREEN_VIEW_PROMO_PAGE_1 = 'SCREEN_VIEW_PROMO_PAGE_1',
  SCREEN_VIEW_PROMO_PAGE_2 = 'SCREEN_VIEW_PROMO_PAGE_2',
  CAMERA_INFO = 'CAMERA_INFO',
  TRIAL_INFO = 'TRIAL_INFO',
  TRIAL_INITIATED = 'TRIAL_INITIATED',
  TRIAL_SKIP_FROM_PROMO_PAGE_1 = 'TRIAL_SKIP_FROM_PROMO_PAGE_1',
  TRIAL_SKIP_FROM_PROMO_PAGE_2 = 'TRIAL_SKIP_FROM_PROMO_PAGE_2',
  SCREEN_VIEW_PLAN_LIST = 'SCREEN_VIEW_PLAN_LIST',
  SELECTION_MONTHLY_BILLING = 'SELECTION_MONTHLY_BILLING',
  SELECTION_YEARLY_BILLING = 'SELECTION_YEARLY_BILLING',
  SCREEN_VIEW_NAVIGATE_TO_APP = 'SCREEN_VIEW_NAVIGATE_TO_APP',
  NAVIGATE_BACK_TO_APP = 'NAVIGATE_BACK_TO_APP',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  CREDIT_CARD_INFO_SUCCESS = 'CREDIT_CARD_INFO_SUCCESS',
  CREDIT_CARD_INFO_CANCEL = 'CREDIT_CARD_INFO_CANCEL',
  TRIAL_CONFIRMED = 'TRIAL_CONFIRMED',
  TRIAL_SKIPPED = 'TRIAL_SKIPPED',
  TRIAL_SKIPPED_REASON = 'TRIAL_SKIPPED_REASON',
  TRIAL_CANCEL = 'TRIAL_CANCEL',
  TRIAL_PURCHASE_FAILED = 'TRIAL_PURCHASE_FAILED',
  SCREEN_VIEW_USER_SUBSCRIPTION_INFO = 'SCREEN_VIEW_USER_SUBSCRIPTION_INFO',
  SUBSCRIPTION_MODIFY = 'SUBSCRIPTION_MODIFY',
  SUBSCRIPTION_UPGRADE = 'SUBSCRIPTION_UPGRADE',
  SUBSCRIPTION_UPGRADE_FAILURE = 'SUBSCRIPTION_UPGRADE_FAILURE',
  SUBSCRIPTION_UPGRADE_SUCCESS = 'SUBSCRIPTION_UPGRADE_SUCCESS',
  FREE_TRIAL_MODIFY = 'FREE_TRIAL_MODIFY',
  FREE_TRIAL_CANCEL = 'FREE_TRIAL_CANCEL',
  FREE_TRIAL_UPGRADE = 'FREE_TRIAL_UPGRADE',
  FREE_TRIAL_UPGRADE_FAILURE = 'FREE_TRIAL_UPGRADE_FAILURE',
  FREE_TRIAL_UPGRADE_SUCCESS = 'FREE_TRIAL_UPGRADE_SUCCESS',
  SUBSCRIPTION_PURCHASE_FAILURE = 'SUBSCRIPTION_PURCHASE_FAILURE',
  SUBSCRIPTION_PURCHASE_SUCCESS = 'SUBSCRIPTION_PURCHASE_SUCCESS',
  SUBSCRIPTION_CANCEL_CLICKED = 'SUBSCRIPTION_CANCEL_CLICKED',
  SUBSCRIPTION_AMOUNT = 'SUBSCRIPTION_AMOUNT',
  SUBSCRIPTION_BUY_NOW = 'SUBSCRIPTION_BUY_NOW',
  SUBSCRIPTION_CANCEL = 'SUBSCRIPTION_CANCEL',
  ADD_NEW_CARD = 'ADD_NEW_CARD',
  REVIEW_PURCHASE_SCREEN = 'REVIEW_PURHCASE_SCREEN',
  PAYMENT_METHOD_SCREEN = 'PAYMENT_METHOD_SCREEN'
}

// Maps app's event names to service-specific event names
export const eventNameMap: Record<AppEvent, string> = {
  [AppEvent.USER_LOGIN]: 'user_login',
  [AppEvent.SCREEN_VIEW_LOGIN_PAGE]: 'screen_view_login_page',
  [AppEvent.SCREEN_VIEW_AUTHORIZING_PAGE]: 'screen_view_authorizing_page', // added
  [AppEvent.SCREEN_VIEW_CAMERA_INFO]: 'screen_view_camera_info', // added
  [AppEvent.SCREEN_VIEW_PROMO_PAGE_1]: 'screen_view_promo_page_1', // added
  [AppEvent.SCREEN_VIEW_PROMO_PAGE_2]: 'screen_view_promo_page_2', // added
  [AppEvent.CAMERA_INFO]: 'camera_info', // added
  [AppEvent.TRIAL_INFO]: 'trial_info', // done
  [AppEvent.TRIAL_INITIATED]: 'trial_initiated', // done
  [AppEvent.TRIAL_SKIP_FROM_PROMO_PAGE_1]: 'trial_skip_from_promo_page_1', // added
  [AppEvent.TRIAL_SKIP_FROM_PROMO_PAGE_2]: 'trial_skip_from_promo_page_2', // added
  [AppEvent.SCREEN_VIEW_PLAN_LIST]: 'screen_view_plan_list', // added
  [AppEvent.SELECTION_MONTHLY_BILLING]: 'selection_monthly_billing', // added
  [AppEvent.SELECTION_YEARLY_BILLING]: 'selection_yearly_billing', // added
  [AppEvent.SCREEN_VIEW_NAVIGATE_TO_APP]: 'screen_view_navigate_to_app', // added
  [AppEvent.NAVIGATE_BACK_TO_APP]: 'navigate_back_to_app', // added
  [AppEvent.PAYMENT_METHOD]: 'payment_method', // check
  [AppEvent.CREDIT_CARD_INFO_SUCCESS]: 'credit_card info_success', // done
  [AppEvent.CREDIT_CARD_INFO_CANCEL]: 'credit_card_info_cancel', // done
  [AppEvent.TRIAL_CONFIRMED]: 'trial_confirmed', // done
  [AppEvent.TRIAL_PURCHASE_FAILED]: 'trial_purchase_failed', // done
  [AppEvent.TRIAL_SKIPPED]: 'trial_skipped', // done
  [AppEvent.TRIAL_SKIPPED_REASON]: 'trial_skipped_reason', // done
  [AppEvent.TRIAL_CANCEL]: 'trial_cancel', // done
  [AppEvent.SCREEN_VIEW_USER_SUBSCRIPTION_INFO]:
    'screen_view_user_subscription_info', // added
  [AppEvent.SUBSCRIPTION_MODIFY]: 'subscription_modify', // done
  [AppEvent.SUBSCRIPTION_UPGRADE]: 'subscription_upgrade', // added
  [AppEvent.SUBSCRIPTION_UPGRADE_FAILURE]: 'subscription_upgrade_failure',
  [AppEvent.SUBSCRIPTION_UPGRADE_SUCCESS]: 'subscription_upgrade_success',
  [AppEvent.FREE_TRIAL_MODIFY]: 'free_trial_modify', // added
  [AppEvent.FREE_TRIAL_CANCEL]: 'free_trial_cancel', // added
  [AppEvent.FREE_TRIAL_UPGRADE]: 'free_trial_upgrade', // added
  [AppEvent.FREE_TRIAL_UPGRADE_FAILURE]: 'free_trial_upgrade_failure', // added
  [AppEvent.FREE_TRIAL_UPGRADE_SUCCESS]: 'free_trial_upgrade_success', // added
  [AppEvent.SUBSCRIPTION_PURCHASE_FAILURE]: 'subscription_purchase_failure', // added
  [AppEvent.SUBSCRIPTION_PURCHASE_SUCCESS]: 'subscription_purchase_success', // added
  [AppEvent.SUBSCRIPTION_CANCEL_CLICKED]: 'subscription_cancel_clicked', // added
  [AppEvent.SUBSCRIPTION_AMOUNT]: 'subscription_amount', // done
  [AppEvent.SUBSCRIPTION_BUY_NOW]: 'subscription_buy_now', // done,
  [AppEvent.SUBSCRIPTION_CANCEL]: 'subscription_cancel', // done
  [AppEvent.ADD_NEW_CARD]: 'add_new_card', // done,
  [AppEvent.PAYMENT_METHOD_SCREEN]: 'payment_method_screen', // done,
  [AppEvent.REVIEW_PURCHASE_SCREEN]: 'review_purchase_screen' // done
};

/**
 * Maps application event properties to Amplitude-specific format
 *
 * @param eventName The standardized app event enum
 * @param properties The original event properties from the application
 * @returns Transformed properties ready for Amplitude
 */
export function mapEventProperties(
  eventName: AppEvent,
  properties: EventProperties
): EventProperties {
  // Default: return properties unchanged
  return properties;

  // Example of property transformation (uncomment and modify as needed)
  /*
    switch (eventName) {
      case AppEvent.PURCHASE:
        return {
          item_id: properties.productId,
          value: properties.price,
          currency: properties.currencyCode || 'USD',
          ...properties
        };
      default:
        return properties;
    }
    */
}
