import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import PaymentScreenDemo from 'pages/PaymentScreen/PaymentScreen.demo';

function AddPaymentMethod() {
  const navigate = useNavigate();

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.billing'
  });

  const addCardDetails = () => {
    navigate(-1);
  };
  return (
    <>
      <Typography variant="h3" margin="30px 0px 15px 10px">
        {t('addCardDetails')}
      </Typography>

      <PaymentScreenDemo
        isUpgrade={false}
        isFreeTrial={false}
        amount={-1}
        submitPurchaseRequest={() => {
          addCardDetails();
        }}
      />
    </>
  );
}

export default AddPaymentMethod;
