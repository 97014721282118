/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */

import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, Stack } from '@mui/material';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { IProps_PopUp, PopupTypes } from 'typings/popUpTypes';

interface StyledDrawerProps extends DrawerProps {
  type?: string;
}

const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'type'
})<StyledDrawerProps>(({ type, theme }) => ({
  zIndex: 1300,
  '& .MuiDrawer-paper': {
    // margin: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: '15px',
    padding: theme.spacing(2.5, 3.25),
    width: isMobile ? '100%' : '40%',
    margin: 'auto',
    bottom: type === PopupTypes.TYPE1 ? '0px' : ''
    // position: type === PopupTypes.TYPE1 ? 'fixed' : 'relative'
  }
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PopUp(props: IProps_PopUp) {
  const {
    isOpen = false,
    type = PopupTypes.TYPE1,
    cross = false,
    onPopUpCloseClick,
    children,
    dialogProps
  } = props;
  return (
    <CustomDrawer
      {...dialogProps}
      open={isOpen}
      type={type}
      anchor="bottom"
      {...(type === PopupTypes.TYPE1 && {
        TransitionComponent: Transition
      })}
    >
      {cross && (
        <Stack direction="row" justifyContent="flex-end">
          <IconButton
            size="small"
            onClick={onPopUpCloseClick}
            sx={{
              right: (theme) => theme.spacing(-2),
              top: (theme) => theme.spacing(-1)
            }}
          >
            <ClearIcon />
          </IconButton>
        </Stack>
      )}
      {children}
    </CustomDrawer>
  );
}
export default PopUp;
