/* eslint-disable @typescript-eslint/no-explicit-any */
import { Card, Divider } from '@mui/material';

function InstaCard(props: any) {
  const { children, disableDivider = false, styles } = props;
  // console.log('props -- ', props);
  return (
    <Card
      sx={{
        borderRadius: '20px',
        position: 'relative',
        padding: '20px 10px',
        boxSizing: 'border-box',
        boxShadow: 'none',
        marginBottom: '20px',
        overflow: 'auto',
        background:
          'linear-gradient(113deg,rgba(238, 238, 238, 1), rgba(238, 238, 238, 1))  padding-box, linear-gradient(356.14deg, #D5D5D5 9.39%, #FFFFFF 88.07%) border-box',
        border: '1px solid transparent',
        '&::before': {
          backgroundImage: 'linear-gradient(45deg, black, transparent)',
          borderRadius: '20px',
          padding: '1px',
          width: '100%',
          height: '100%',
          top: '-1px',
          left: '-1px',
          zIndex: '-1',
          position: 'absolute',
          content: '""'
        },
        ...styles
      }}
    >
      {Array.isArray(children) &&
        children.map((child: any, index: any) => (
          <>
            {child}
            {!disableDivider && index !== children.length - 1 && <Divider />}
          </>
        ))}
      {!Array.isArray(children) && children}
    </Card>
  );
}

export default InstaCard;
