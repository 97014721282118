import { Box, Stack, Typography } from '@mui/material';
import LaunchAppButton from 'components/LaunchAppButton';
import PopUp from 'coreComponents/PopUp/PopUp';
import { ConfigContext } from 'context';
import InstavisionButton from 'coreComponents/Button/Button';
import { AppBridge } from 'infrastructure/appBridge/appBridge';
import { APP_BRIDGE_CALLBACKS } from 'infrastructure/appBridge/appCallBacks';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PopupTypes } from 'typings/popUpTypes';
import { isRunningInWebView } from 'utils/webviewHelpers';
import { PopupIllustrationImage } from '../popupIllustrationStyle';

interface TPaymentConfirmedPopupProps {
  planCycle: number;
  planName: string;
}

function PaymentConfirmedPopup(props: TPaymentConfirmedPopupProps) {
  const { planCycle, planName } = props;
  const [isPopUpOpen, setIsPopUpOpen] = useState(true);
  const config = useContext(ConfigContext);
  const PaymentSuccessIcon = config.paymentSuccessfull;

  const { t } = useTranslation('translation', {
    keyPrefix: 'components.paymentSuccessPopup'
  });

  // To do: Add App Callback here to take to live stream
  const handlePopUpBackButtonClick = () => {
    setIsPopUpOpen(false);
    AppBridge.sendMessageToApp(APP_BRIDGE_CALLBACKS.CLOSE_IN_APP_WEBVIEW);
  };

  const isWebViewPresent = isRunningInWebView();

  return (
    <PopUp isOpen={isPopUpOpen} type={PopupTypes.TYPE2}>
      <Stack direction="column" alignItems="center" marginBottom="30px">
        <Stack marginBottom="12px">
          <PopupIllustrationImage
            src={PaymentSuccessIcon}
            alt="payment-failed"
          />
        </Stack>
        <Typography variant="h2" paddingBottom="12px">
          {t('paymentConfirmedTitle')}
        </Typography>
        <Box textAlign="center">
          <Typography
            variant="Body1Medium"
            padding="0px 12px"
            color="text.shadedText"
          >
            {t('thankYouForPurchase', {
              planName:
                planCycle === 1 ? `${planName} Monthly` : `${planName} Yearly`,
              companyName: config.nameOfCompany
            })}
          </Typography>
        </Box>
      </Stack>
      <Stack direction="column" alignItems="Stretch" marginBottom="6px">
        {isWebViewPresent ? (
          <InstavisionButton
            sx={{
              marginBottom: '16px',
              fontSize: (theme) => theme.typography.h3.fontSize,
              fontWeight: (theme) => theme.typography.h3.fontWeight,
              lineHeight: (theme) => theme.typography.h3.lineHeight
            }}
            onClick={() => handlePopUpBackButtonClick()}
          >
            {t('goHome')}
          </InstavisionButton>
        ) : (
          <LaunchAppButton />
        )}
      </Stack>
    </PopUp>
  );
}
export default PaymentConfirmedPopup;
