export const APP_BRIDGE_CALLBACKS = {
  SHOW_CLOSE_BUTTON: 'showCloseButton',
  HIDE_CLOSE_BUTTON: 'hideCloseButton',
  SHOW_RELOAD_BUTTON: 'showReloadButton',
  HIDE_RELOAD_BUTTON: 'hideReloadButton',
  SHOW_BACK_BUTTON: 'showBackButton',
  HIDE_BACK_BUTTON: 'hideBackButton',
  GET_ACCESS_TOKEN: 'getAccessToken',
  CLOSE_IN_APP_WEBVIEW: 'closeInAppWebView',
  UPDATED_SUBSCRIPTION: 'updatedSubscription'
};
