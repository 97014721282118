import {
  Button,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Invoice from 'assets/Invoice.svg';
import InstaCard from 'coreComponents/InstaCard';
import { homeSecurityPortalApi } from 'data/axios';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { endpoints } from 'services/api/endpoints';
import { GetAllSpaces } from 'services/api/SpacesApi';
import { Image } from 'styles';
import { SpaceRole, TSpaceResponseData, TTransaction } from 'typings';
import { downloadFile } from 'utils/commonFunctions';
import {
  APP_BUTTON_TYPES,
  isRunningInWebView,
  showAppButton
} from 'utils/webviewHelpers';

function BillingAndInvoiceSummary() {
  const [transactionList, setTransactionList] = useState<TTransaction[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(true);
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(true);
  const [totalTransactionsCount, setTotalTransactionsCount] = useState(0);
  const [isTransactionsFetched, setIsTransactionsFetched] = useState(false);
  const [loadErr, setLoadErr] = useState('');
  const [spaceData, setSpaceData] = useState<TSpaceResponseData[]>([]);

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.billing'
  });

  useEffect(() => {
    setIsLoading(isSubscriptionLoading || isTransactionsLoading);
  }, [isSubscriptionLoading, isTransactionsLoading]);

  const fetchSubscriptionAndSpacesData = async () => {
    try {
      const allSpacesResponse = await GetAllSpaces();

      // Extract and return relevant data
      const ownerSpace = allSpacesResponse.data.filter(
        (item) => item.role === SpaceRole.OWNER
      );

      return {
        spaceData: ownerSpace
      };
    } catch (error) {
      console.log('Error fetching subscription and space data:', error);
      throw error; // Re-throw error for handling in the caller
    }
  };

  useEffect(() => {
    if (isRunningInWebView()) {
      showAppButton(APP_BUTTON_TYPES.RELOAD, APP_BUTTON_TYPES.CLOSE);
    }
  }, [isRunningInWebView]);

  const fetchData = async () => {
    setIsSubscriptionLoading(true);
    try {
      const { spaceData: newSpaceData } =
        await fetchSubscriptionAndSpacesData();

      // Update the existing state variables

      setSpaceData(newSpaceData);
    } catch (error) {
      // Handle errors
      console.error('Failed to fetch data:', error);
    } finally {
      setIsSubscriptionLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const LoadMoreDetails = useCallback(() => {
    setIsTransactionsLoading(true);
    setIsTransactionsFetched(false);

    homeSecurityPortalApi
      .get(endpoints.getTransactionsUrl)
      .then((res) => {
        if (res.data !== null) {
          setTransactionList((state) => state.concat(res.data.items));
          setTotalTransactionsCount(res.data.total_count);
        } else {
          setLoadErr('No more payments to load');
        }
      })
      .catch((err) => {
        console.log('purchase', err);
        setLoadErr('No more payments to load');
      })
      .finally(() => {
        setIsTransactionsFetched(true);
        setIsTransactionsLoading(false);
      });
  }, []);

  const downloadInvoice = (transaction_id: string) => {
    homeSecurityPortalApi
      .get(endpoints.billings(transaction_id))
      .then((res) => {
        // window.location.href = res?.data?.invoice_link;
        downloadFile({
          url: res?.data?.invoice_link,
          filename: `invoice-${transaction_id}.pdf`
        });
      })
      .catch((err) => {
        console.log('download error', err);
      });
  };

  useEffect(() => {
    if (isLoading && !isTransactionsFetched) {
      LoadMoreDetails();
    }
  }, [LoadMoreDetails, isLoading, isTransactionsFetched]);

  if (isLoading) {
    return (
      <Stack gap="20px">
        <Skeleton
          variant="rounded"
          height={32}
          animation="pulse"
          sx={{ borderRadius: '8x' }}
        />
        <Skeleton
          variant="rounded"
          height={640}
          animation="pulse"
          sx={{ borderRadius: '20px' }}
        />
      </Stack>
    );
  }
  return (
    <>
      {spaceData.length > 0 && !isLoading && (
        <>
          <Typography variant="h3" marginBottom="16px">
            {t('transactionHistory')}
          </Typography>
          <TableContainer>
            {isTransactionsFetched && transactionList.length > 0 && (
              <Table
                sx={{
                  minWidth: 650,
                  borderRadius: '20px',
                  background:
                    'linear-gradient(113deg,rgba(228, 228, 228, 1), rgba(241, 241, 241, 1)) padding-box, linear-gradient(356.14deg, #D5D5D5 9.39%, #FFFFFF 88.07%) border-box',
                  border: '1px solid transparent',
                  overflow: 'hidden'
                }}
              >
                <TableHead sx={{ border: 0 }}>
                  <TableRow sx={{ background: '#EDEDED' }}>
                    <TableCell align="justify">
                      <Typography variant="h6">{t('transaction')}</Typography>
                    </TableCell>
                    <TableCell align="justify">
                      <Typography variant="h6">{t('status')}</Typography>
                    </TableCell>
                    <TableCell align="justify">
                      <Typography variant="h6">{t('type')}</Typography>
                    </TableCell>
                    <TableCell align="justify">
                      <Typography variant="h6">{t('amount')}</Typography>
                    </TableCell>
                    <TableCell align="justify">
                      <Typography variant="h6">
                        {t('transactionTime')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactionList.map((transaction: TTransaction) => (
                    <TableRow
                      key={transaction.id}
                      sx={{
                        border: 0,
                        '&:nth-of-type(odd)': { background: '#E8E8E8' },
                        '&:nth-of-type(even)': { background: '#EDEDED' }
                      }}
                    >
                      <TableCell
                        sx={{
                          border: 0,
                          fontSize: (theme) =>
                            theme.typography.Body1SemiBold.fontSize,
                          fontWeight: (theme) =>
                            theme.typography.Body1SemiBold.fontWeight,
                          lineHeight: (theme) =>
                            theme.typography.Body1SemiBold.lineHeight
                        }}
                      >
                        {transaction.sku_name}
                      </TableCell>
                      <TableCell sx={{ border: 0 }} align="justify">
                        <Typography
                          variant="Body1SemiBold"
                          color={
                            transaction.status === 'Success'
                              ? '#32D284'
                              : 'background.orange'
                          }
                          fontWeight={500}
                        >
                          {transaction.status}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="justify"
                        sx={{
                          border: 0,
                          fontSize: (theme) =>
                            theme.typography.Body1SemiBold.fontSize,
                          fontWeight: (theme) =>
                            theme.typography.Body1SemiBold.fontWeight,
                          lineHeight: (theme) =>
                            theme.typography.Body1SemiBold.lineHeight
                        }}
                        onClick={() => downloadInvoice(transaction.id)}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            background: (theme) => theme.palette.primary.main,
                            display: 'flex',
                            padding: '8px'
                          }}
                        >
                          <Image
                            src={Invoice}
                            height="16"
                            width="16"
                            style={{ marginRight: '6px' }}
                          />
                          <Typography variant="Body1SemiBold" color="white">
                            {t('invoice')}
                          </Typography>
                        </Button>
                      </TableCell>
                      <TableCell align="justify" sx={{ border: 0 }}>
                        <Typography variant="Body1SemiBold">
                          ${transaction.amount / 100}
                        </Typography>
                      </TableCell>
                      <TableCell align="justify" sx={{ border: 0 }}>
                        <Typography variant="Body1SemiBold">
                          {dayjs(transaction.transaction_time).format(
                            'YYYY.MM.DD HH:mm:ss'
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
          {loadErr === '' &&
            isTransactionsFetched &&
            totalTransactionsCount === 0 && (
              <Stack justifyContent="center" alignItems="center">
                <Typography variant="Body1SemiBold" sx={{ cursor: 'auto' }}>
                  {t('noTransactions')}
                </Typography>
              </Stack>
            )}
          {loadErr !== '' && (
            <Stack justifyContent="center">
              <Typography variant="Body1SemiBold">{loadErr}</Typography>
            </Stack>
          )}
        </>
      )}

      {spaceData.length === 0 && !isLoading && (
        <InstaCard>
          <Stack alignItems="center">
            <Typography variant="h3">{t('noSpaceCreated')}</Typography>
          </Stack>
        </InstaCard>
      )}
    </>
  );
}

export default BillingAndInvoiceSummary;
