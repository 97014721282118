import PaymentMethod from 'pages/subscriptions/paymentMethod';
import { useEffect } from 'react';
import {
  APP_BUTTON_TYPES,
  isRunningInWebView,
  showAppButton
} from 'utils/webviewHelpers';

function PaymentMethodScreen() {
  useEffect(() => {
    if (isRunningInWebView()) {
      showAppButton(APP_BUTTON_TYPES.RELOAD, APP_BUTTON_TYPES.CLOSE);
    }
  }, []);

  const handleAddNewCardToggle = (isOpen: boolean) => {
    if (isRunningInWebView()) {
      if (isOpen) {
        // hideAppButton();
        showAppButton(APP_BUTTON_TYPES.RELOAD);
      } else {
        showAppButton(APP_BUTTON_TYPES.RELOAD, APP_BUTTON_TYPES.CLOSE);
      }
    }
  };

  return <PaymentMethod onAddNewCardToggle={handleAddNewCardToggle} />;
}

export default PaymentMethodScreen;
