import * as amplitude from '@amplitude/analytics-browser';
import { Experiment, ExperimentClient } from '@amplitude/experiment-js-client';
import { AnalyticsAdapter, EventProperties, UserProperties } from './types';

export class AmplitudeAdapter implements AnalyticsAdapter {
  private initialized: boolean = false;

  private experimentInitialized: boolean = false;

  private experimentClient?: ExperimentClient;

  initialize(
    apiKey: string,
    options: amplitude.Types.BrowserOptions & {
      experimentApiKey?: string;
    }
  ): void {
    if (!this.initialized) {
      amplitude.init(apiKey, {
        ...options
      });
      this.initialized = true;
      console.log('Amplitude analytics initialized');

      if (options.experimentApiKey) {
        this.initializeExperiment(options.experimentApiKey);
      }
    }
  }

  setUserId(userId: string | null): void {
    if (!this.initialized) {
      console.warn('Amplitude not initialized');
      return;
    }
    if (!userId) {
      console.warn('User ID not provided');
      return;
    }
    amplitude.setUserId(userId);
  }

  trackEvent(eventName: string, eventProperties: EventProperties = {}): void {
    if (!this.initialized) {
      console.warn('Amplitude not initialized');
      return;
    }
    amplitude.track(eventName, eventProperties);
  }

  setUserProperties(properties: UserProperties): void {
    if (!this.initialized) {
      console.warn('Amplitude not initialized');
      return;
    }

    amplitude.identify(
      Object.entries(properties).reduce((identify, [key, value]) => {
        if (value !== null) {
          identify.set(key, value);
        }
        return identify;
      }, new amplitude.Identify())
    );
  }

  setGroup(groupType: string, groupName: string | string[]): void {
    if (!this.initialized) {
      console.warn('Amplitude not initialized');
      return;
    }
    amplitude.setGroup(groupType, groupName);
  }

  setUserPathInLocalStorage(userPath: string): void {
    if (!this.initialized) {
      console.warn('Amplitude not initialized');
      return;
    }
    localStorage.setItem('user_path', userPath);
  }

  getUserPathFromLocalStorage(): string {
    if (!this.initialized) {
      console.warn('Amplitude not initialized');
      return '';
    }
    return localStorage.getItem('user_path') || '';
  }

  // Amplitude Experiments methods

  initializeExperiment(experimentApiKey: string): void {
    if (!this.experimentInitialized) {
      this.experimentClient =
        Experiment.initializeWithAmplitudeAnalytics(experimentApiKey);
      this.experimentInitialized = true;
      console.log('Amplitude Experiments initialized');
    }
  }

  async fetchExperiment(): Promise<void> {
    if (!this.experimentInitialized || !this.experimentClient) {
      console.warn('Amplitude Experiments not initialized');
      throw new Error('Amplitude Experiments not initialized');
    }
    const experiment = await this.experimentClient.fetch();
    console.log('Experiement loaded:::::::::::::::', experiment);
  }

  fetchVariant(
    flagKey: string
  ): { value: string | null; payload?: Record<string, unknown> } | null {
    if (!this.experimentInitialized || !this.experimentClient) {
      console.warn('Amplitude Experiments not initialized');
      return null;
    }
    const variant = this.experimentClient.variant(flagKey);
    return variant
      ? { value: variant.value || '', payload: variant.payload }
      : null;
  }
}
