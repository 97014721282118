import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Skeleton,
  Stack,
  styled,
  Typography
} from '@mui/material';
import InstaCard from 'coreComponents/InstaCard';
import MenuPopup from 'coreComponents/MenuPopup';
import PopUp from 'coreComponents/PopUp/Drawer';
import InstavisionButton from 'coreComponents/Button/Button';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DeletePaymentMethod,
  GetPaymentMethods,
  UpdateDefaultPaymentMethod
} from 'services/api/paymentsApi';
import { changePaymentMethod } from 'services/api/subscriptionApi';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchAllSubscription } from 'store/reducers/subscriptionReducer';
import { Image } from 'styles';
import {
  SUBSCRIPTION_TYPE,
  TPaymentMethods,
  TSubScriptionState
} from 'typings';
import { PopupTypes } from 'typings/popUpTypes';
import { getCardImageProps } from 'utils/commonFunctions';
import { isRunningInWebView } from 'utils/webviewHelpers';
import PaymentScreenDemo from 'pages/PaymentScreen/PaymentScreen.demo';
import AddIcon from '@mui/icons-material/Add';
import SampleCardIcon from 'assets/sampleCreditCard.svg';
import analyticsService, {
  AppEvent
} from 'infrastructure/serviceHandlers/Analytics/Analytics';

const MenuWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 12px',
  '&:hover': {
    color: '#000000',
    background: '#bdbdbd'
  }
});

function PaymentMethod({
  handleUpdateDefaultCard,
  onAddNewCardToggle,
  showAddNewCardButton = true
}: {
  handleUpdateDefaultCard?: (cardId: string) => void;
  onAddNewCardToggle?: (isOpen: boolean) => void;
  showAddNewCardButton?: boolean;
}) {
  const [isCardListLoading, setIsCardListLoading] = useState(true);
  const dispatch = useAppDispatch();
  const { subscriptionData } = useAppSelector(
    (state) => (state.subscription as TSubScriptionState) || []
  );

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.subscription.subscriptionList'
  });

  const subscriptionCard = subscriptionData?.[0]?.card_details;

  const [selectedCardId, setSelectedCardId] = useState('');
  const [paymentOption, setPaymentOption] = useState<TPaymentMethods[]>([]);
  const [isNewCardModalOpen, setIsNewCardModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteCardId, setDeleteCardId] = useState('');

  // const addNewPaymentMethod = () => {
  //   setIsNewCardModalOpen(true);
  // };

  useEffect(() => {
    if (isRunningInWebView() && onAddNewCardToggle) {
      onAddNewCardToggle(isNewCardModalOpen);
    }
  }, [isNewCardModalOpen, onAddNewCardToggle]);

  useEffect(() => {
    analyticsService.trackEvent(AppEvent.PAYMENT_METHOD_SCREEN);
  }, []);

  // initializeLoader - show/hide loader when fetching card list, useful when fetching the list multiple times when adding new card
  const fetchSavedCards = (initializeLoader: boolean = true) => {
    if (initializeLoader) setIsCardListLoading(true);
    GetPaymentMethods()
      .then((res) => {
        setPaymentOption(res.data.payment_method || []);
        setSelectedCardId(
          res.data.default_payment_method ||
            res.data.payment_method[0]?.id ||
            ''
        );
      })
      .catch((err) => console.log('err payment_methods', err))
      .finally(() => {
        if (initializeLoader) setIsCardListLoading(false);
      });
  };

  const handleCreditCardChange = async (
    cardId: string,
    newlyAdded?: boolean
  ) => {
    //  Make API call to update payment method
    const payload = {
      stripe_payment_method: cardId
    };

    UpdateDefaultPaymentMethod(cardId).then(() => {
      setSelectedCardId(cardId);
      if (newlyAdded) {
        fetchSavedCards();
      }
      if (handleUpdateDefaultCard) {
        // update selected payment id in parent component
        handleUpdateDefaultCard(cardId);
      }
    });
    // if subscriptionData is empty, no need to update the subscription
    if (subscriptionData.length === 0) {
      return;
    }
    changePaymentMethod(subscriptionData[0]?.id, payload)
      .then(() => {
        setSelectedCardId(cardId);
        dispatch(fetchAllSubscription());
        if (newlyAdded) {
          fetchSavedCards(false);
        }
      })
      .catch((err) => {
        // TODO: Add failure toast message
        console.error(err);
      });

    // Note: Data is not reloaded here.
    // The actual change occurs in the Piggyback service.
    // Subscription data is sourced from the Atlas service.
    // It may take a few seconds for changes to reflect in the other service.
  };

  const handleDeleteCard = (cardId: string) => {
    DeletePaymentMethod(cardId)
      .then(() => {
        setSelectedCardId((prevCardId) => {
          if (prevCardId === cardId) {
            return paymentOption?.[0]?.id || '';
          }
          return prevCardId;
        });
        dispatch(fetchAllSubscription());
        fetchSavedCards();
      })
      .catch((err) => {
        console.error('Error deleting payment method: ', err);
      })
      .finally(() => {
        setDeleteCardId('');
        setIsDeleteModalOpen(false);
      });
  };

  useEffect(() => {
    // fetchData();
    fetchSavedCards();
  }, []);

  useEffect(() => {
    if (subscriptionCard === undefined) {
      return;
    }

    // Check if there's a card that matches the subscriptionCard id
    let selectedCard = paymentOption.find(
      (el: {
        id: string;
        card: {
          exp_month: number;
          exp_year: number;
          last4: string;
        };
      }) => el.id === subscriptionCard?.id
    );

    // Note: old cards in subscription api's card details object may not have id
    // for such cases match last 4 digits and expiry details to find the card

    if (!selectedCard) {
      selectedCard = paymentOption.find(
        (el: {
          id: string;
          card: {
            exp_month: number;
            exp_year: number;
            last4: string;
          };
        }) =>
          el.card.exp_month === subscriptionCard?.exp_month &&
          el.card.exp_year === subscriptionCard?.exp_year &&
          el.card.last4 === subscriptionCard?.last4
      );
    }

    const id = selectedCard?.id || ''; // Default to empty string if no match
    // console.log('selected card', id);
    setSelectedCardId(id);
  }, [paymentOption, subscriptionCard]);

  if (isCardListLoading)
    return (
      <>
        <Skeleton
          variant="rounded"
          height={32}
          animation="pulse"
          sx={{ borderRadius: '8px', marginBottom: '16px' }}
        />
        <Skeleton
          variant="rounded"
          height={200}
          animation="pulse"
          sx={{ borderRadius: '20px', marginBottom: '16px' }}
        />
      </>
    );

  // console.log('payment option', paymentOption);

  return (
    <div>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        marginBottom="16px"
      >
        <Typography variant="h3" textTransform="uppercase">
          {t('paymentMethod')}
        </Typography>
      </Stack>
      {paymentOption.length > 0 && (
        <InstaCard styles={{ padding: '0px 12px' }}>
          {paymentOption.map(
            (el: {
              id: string;
              card: {
                id: string;
                brand: string;
                last4: string;
                exp_month: number;
                exp_year: number;
              };
            }) => {
              const {
                id,
                card: { last4, brand, exp_month, exp_year }
              } = el;
              const { src, width, height } = getCardImageProps(brand);
              return (
                <Box
                  key={id}
                  sx={{
                    justifyContent: 'space-between',
                    width: '100%',
                    margin: (theme) => theme.spacing(2, 0)
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={3}
                    justifyContent="space-between"
                  >
                    <Stack direction="row" alignItems="center" gap={3}>
                      <Image src={src} width={width} height={height} />
                      <Stack gap={1.5}>
                        <Typography variant="h4">
                          **** **** **** {last4}
                        </Typography>
                        <Typography
                          variant="Body1Medium"
                          color="text.shadedText"
                        >{`${t(
                          'expiryDate'
                        )} ${exp_month}/${exp_year}`}</Typography>
                        {id === selectedCardId && (
                          <Typography variant="Body1Medium" color="#007BFF">
                            {t('cardUseNote')}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                    {paymentOption?.length > 1 && (
                      <MenuPopup
                        sx={{
                          paddingLeft: '12px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'flex-start',
                          cursor: 'pointer'
                        }}
                        disablePopperButton={id === selectedCardId}
                        render={({ handleClose }) => {
                          return (
                            <>
                              <MenuWrapper
                                onClick={() => {
                                  handleCreditCardChange(id);
                                  handleClose();
                                }}
                              >
                                <p style={{ margin: '0px 16px' }}>
                                  Make Default
                                </p>
                              </MenuWrapper>
                              <MenuWrapper
                                onClick={() => {
                                  handleClose();
                                  setDeleteCardId(id);
                                  setIsDeleteModalOpen(true);
                                }}
                              >
                                <p style={{ margin: '0px 16px' }}>
                                  Delete Card
                                </p>
                              </MenuWrapper>
                            </>
                          );
                        }}
                      />
                    )}
                  </Stack>
                </Box>
              );
            }
          )}
        </InstaCard>
      )}
      {/* <Button
        variant="text"
        onClick={addNewPaymentMethod}
        sx={{
          paddingLeft: '4px',
          marginTop: 0,
          paddingTop: 0,
          fontSize: '16px',
          width: 'fit-content'
        }}
      >
        + {t('addNewCard')}
      </Button> */}
      {showAddNewCardButton && (
        <InstaCard styles={{ padding: '8px 12px', marginBottom: '8px' }}>
          <Accordion
            disableGutters
            square
            elevation={0}
            sx={{
              '&:before': { display: 'none' },
              backgroundColor: 'transparent',
              boxShadow: 'none',
              padding: '8px'
            }}
          >
            <AccordionSummary
              expandIcon={<AddIcon />}
              sx={{
                padding: '0 4px 0 0',
                textAlign: 'left',
                wordSpacing: '0.02em'
              }}
            >
              <Stack direction="row" alignItems="center" gap={3}>
                <img src={SampleCardIcon} alt="sample-card" width="32" />
                <Typography
                  variant="h3"
                  fontWeight={500}
                  lineHeight={1.2}
                  fontSize="20px"
                >
                  {t('addNewCard')}
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: '12px 0',
                textAlign: 'left',
                wordSpacing: '0.02em',
                marginTop: 0
              }}
            >
              <PaymentScreenDemo
                isUpgrade={false}
                isFreeTrial={false}
                amount={-1}
                submitPurchaseRequest={(cardId: string) => {
                  handleCreditCardChange(cardId, true);
                  setIsNewCardModalOpen(false);
                }}
                isPreviewPlan={
                  subscriptionData[0]?.subscription_type ===
                  SUBSCRIPTION_TYPE.PREVIEW
                }
              />
            </AccordionDetails>
          </Accordion>
        </InstaCard>
      )}

      {/* <PopUp
        isOpen={isNewCardModalOpen}
        onPopUpCloseClick={() => {
          setIsNewCardModalOpen(false);
          setDeleteCardId('');
        }}
        cross
        type={PopupTypes.TYPE2}
        dialogProps={{
          scroll: 'body'
        }}
      >
        <Stack>
          <Typography variant="h3" marginX="auto" marginBottom="36px">
            {t('addCardDetails')}
          </Typography>
        </Stack>
        <PaymentScreenDemo
          isUpgrade={false}
          isFreeTrial={false}
          amount={-1}
          submitPurchaseRequest={(cardId: string) => {
            handleCreditCardChange(cardId, true);
            setIsNewCardModalOpen(false);
          }}
          isPreviewPlan={
            subscriptionData[0]?.subscription_type === SUBSCRIPTION_TYPE.PREVIEW
          }
        />
      </PopUp> */}
      {/* Delete confirmation popup */}
      <PopUp
        isOpen={isDeleteModalOpen}
        onPopUpCloseClick={() => setIsDeleteModalOpen(false)}
        type={PopupTypes.TYPE2}
      >
        <Stack
          direction="column"
          alignItems="center"
          textAlign="center"
          marginBottom="12px"
        >
          <Typography variant="h2" paddingBottom="12px">
            {/* {t('deleteCardMessage')} */}
            Delete Payment Method
          </Typography>
          <Typography paddingBottom="12px">
            Are you sure you want to delete this payment method?
          </Typography>
        </Stack>

        <Stack direction="column" alignItems="stretch" marginBottom="6px">
          <InstavisionButton
            color="inherit"
            sx={{ marginBottom: '12px', fontSize: '1.2rem' }}
            onClick={() => handleDeleteCard(deleteCardId)}
          >
            {/* {t('delete')} */}
            Delete
          </InstavisionButton>
          <Button
            variant="text"
            color="secondary"
            onClick={() => setIsDeleteModalOpen(false)}
            sx={{ textTransform: 'capitalize' }}
            className="btn-no-focus"
          >
            {/* {t('cancel')} */}
            Cancel
          </Button>
        </Stack>
      </PopUp>
    </div>
  );
}

export default PaymentMethod;
