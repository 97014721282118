import InstavisionButton from 'coreComponents/Button/Button';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function LaunchAppButton() {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.launchAppButton'
  });
  return (
    <InstavisionButton
      color="inherit"
      fullWidth
      sx={{
        marginBottom: '16px',
        fontSize: '1.4rem'
      }}
      onClick={() => {
        if (isMobile && (isAndroid || isIOS)) {
          navigate('/launchMobileApp');
        } else {
          navigate('/dashboard');
        }
      }}
    >
      {t('goBackToApp')}
    </InstavisionButton>
  );
}
export default LaunchAppButton;
