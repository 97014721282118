/* eslint-disable no-nested-ternary */

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  Stack,
  styled,
  Typography
} from '@mui/material';
import {
  HOLIDAY_DISCOUNT_PERCENTAGE,
  IS_HOLIDAY_DISCOUNT_ACTIVE
} from 'configs/coupon';
import { ConfigContext } from 'context';
import InstavisionButton from 'coreComponents/Button/Button';
import analyticsService, {
  AppEvent
} from 'infrastructure/serviceHandlers/Analytics/Analytics';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { TPlanList } from 'typings';
import { formatPrice, getSupportedCameraCount } from 'utils/commonFunctions';
import EnhancedPlanIcon from '../../assets/subscriptionIcons/enhance_plan_icon.svg';
import PremiumPlanIcon from '../../assets/subscriptionIcons/premium_plan_icon.svg';
import SoloPlanIcon from '../../assets/subscriptionIcons/solo_plan_icon.svg';
import PromoRibbon from './PromoRibbon';
// eslint-disable-next-line import/order

const planIcons = [PremiumPlanIcon, EnhancedPlanIcon, SoloPlanIcon];

// const InformationText = styled('p')`
//   color: #4389e1;
//   margin: 0px;
// `;

// const InformationContainer = styled('div')`
//   padding-top: 30px;
//   text-align: center;
// `;

const PlanCard = styled(Card)<{ selected: boolean; planIndex: number }>(
  ({ theme, planIndex, selected }) => ({
    overflow: planIndex === 1 ? 'visible' : 'hidden',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '64px',
    height: '100%',
    // cursor: 'pointer',
    padding: 52,
    paddingBottom: 8,
    // transition: 'all 0.3s ease',
    borderRadius: 10,
    border: selected
      ? `0.5px solid ${theme.palette.primary.main}`
      : '0.5px solid rgba(204, 204, 204, 1)',
    backgroundColor: selected
      ? 'rgba(67, 137, 225, 0.1)'
      : theme.palette.background.paper
    // '&:hover': {
    //   transform: 'translateY(-4px)',
    //   boxShadow: theme.shadows[4]
    // }
  })
);

const FeatureContainer = styled(Box)<{
  planIndex: number;
  feature: string;
  containerWidth: number | null;
}>(({ feature, planIndex, containerWidth }) => ({
  height: 40,
  width: 24,
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  position: 'relative',
  // Show the feature box only for the middle plan
  ...(planIndex === 1 && {
    '&:before': {
      content: `"${feature}"`,
      position: 'absolute',
      top: '0',
      left: '0',
      transform: 'translateX(-46.5%) translateY(-100%)',
      padding: '8px 16px',
      width: containerWidth ? 0.98 * containerWidth : 0.98 * 400,
      backgroundColor: 'rgba(255, 255, 255, 1)',
      border: '0.5px solid rgba(204, 204, 204, 1)',
      zIndex: 1,
      borderRadius: 4,
      overflow: 'visible',
      fontSize: '12px',
      fontWeight: 'regular',
      textAlign: 'center',
      pointerEvents: 'none',
      whiteSpace: 'nowrap'
    }
  })
}));

const PricingButton = styled(Button)<{ isYearly: boolean; padding?: string }>(
  ({ isYearly }) => ({
    width: '50%',
    height: '100%',
    display: 'flex',
    whiteSpace: 'nowrap', // Prevent wrapping
    overflow: 'hidden', // Handle overflow
    textOverflow: 'ellipsis',
    flexDirection: 'column',
    color: !isYearly ? 'rgba(255, 255, 255, 1)' : 'rgba(51, 51, 51, 1)',
    fontSize: '16px',
    // padding: padding || '12px 44px',
    paddingTop: '12px',
    paddingBottom: '12px',
    borderRadius: '10px',
    background: !isYearly
      ? 'linear-gradient(180deg, #4389E1 0%, #5496FF 50%, #185AAD 100%)'
      : 'linear-gradient(95.72deg, #FFFFFF 5.26%, #DEDEDE 98.93%)',
    boxShadow: !isYearly
      ? '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      : '0px 0px 4px 0px rgba(0, 0, 0, 0.21)',
    border: !isYearly ? 'none' : '0.5px solid',

    borderImageSource:
      'linear-gradient(104.96deg, #E7E7E7 2.27%, #FFFFFF 60.54%)',

    textTransform: 'none'
  })
);

type TProps__PlansComparision = {
  plansData: TPlanList[];
  allPlans: TPlanList[];
  allowSelection?: boolean;
  onPlanSelection?: (plan: TPlanList) => void;
  filterByLevel?: (plan: TPlanList) => number | boolean;
  isFreeTrial?: boolean;
  isUpgrade?: boolean;
  onChange: (arg0: boolean, planList: TPlanList[]) => void;
};

function PlanComparisonComponent(props: TProps__PlansComparision) {
  const {
    plansData,
    allowSelection = false,
    onPlanSelection,
    filterByLevel,
    isFreeTrial,
    isUpgrade,
    onChange,
    allPlans
  } = props;

  // console.log('is free trial', isFreeTrial);

  const planContainerRef = useRef<HTMLDivElement>(null);
  const config = useContext(ConfigContext);

  const [isYearly, setIsYearly] = useState(true);
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();
  const [selectedPlan, setSelectedPlan] = useState<TPlanList>();
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.subscription.planPage'
  });

  const [planContainerWidth, setPlanContainerWidth] = useState<number | null>(
    null
  );

  const [
    isCancellationAndRefundPolicyAccepted,
    setIsCancellationAndRefundPolicyAccepted
  ] = useState(true);

  const enableHolidayPromo = IS_HOLIDAY_DISCOUNT_ACTIVE && !isUpgrade;

  useEffect(() => {
    if (planContainerRef.current) {
      setPlanContainerWidth(planContainerRef.current.clientWidth);
    }
  }, [plansData]);

  useEffect(() => {
    if (plansData?.length > 0) {
      const defaultPlan = plansData.find((plan) => plan.level === 3);
      setSelectedPlan(defaultPlan);
    }
  }, [plansData]);

  // const handleRadioChange = (plan: TPlanList, selectedIndex: number) => {
  //   if (filterByLevel && !filterByLevel(plan)) {
  //     return;
  //   }
  //   console.log('clicked radio', plan, selectedIndex);
  //   setSelectedPlan(plan);
  // };

  const handleRadioChange = (plan: TPlanList, selectedIndex: number) => {
    if (filterByLevel && !filterByLevel(plan)) {
      return;
    }

    console.log('clicked radio', plan, selectedIndex);
    setSelectedPlan(plan);

    // Map plan levels to their corresponding names
    const planName =
      plan.level === 1
        ? 'solo'
        : plan.level === 2
        ? 'enhanced'
        : plan.level === 3
        ? 'premium'
        : 'solo'; // Default case for safety

    // Track the event with Amplitude
    analyticsService.trackEvent(
      isYearly
        ? AppEvent.SELECTION_YEARLY_BILLING
        : AppEvent.SELECTION_MONTHLY_BILLING,
      {
        plan_level: plan.level, // Add the plan level as a property
        plan_name: planName // Add the plan name as a property
      }
    );

    console.log(`Selected plan: ${planName}`);
  };

  const checkUpgradeEligibility = useMemo(() => {
    // check if none of the plans are available for upgrade
    return plansData.every((plan) => filterByLevel && !filterByLevel(plan));
  }, [filterByLevel, plansData]);

  const uniqueFeatures = Array.from(
    new Set(plansData.flatMap((plan) => plan.description))
  );

  const handleToggleYearlyMonthly = (yearly: boolean) => {
    setIsYearly(yearly);
    onChange(yearly, allPlans);
  };

  const getCorrespondingYearlyPlan = useMemo(() => {
    if (selectedPlan) {
      const yearlyPlan = allPlans.find(
        (plan) =>
          plan.level === selectedPlan.level &&
          plan.name === selectedPlan.name &&
          plan.subscription_cycle === 12
      );
      return yearlyPlan;
    }
    return null;
  }, [selectedPlan, allPlans]);

  const getCorrespondingMonthlyPlan = (plan?: TPlanList) => {
    if (plan) {
      const monthlyPlan = allPlans.find(
        (eachPlan) =>
          eachPlan.level === plan.level &&
          eachPlan.name === plan.name &&
          eachPlan.subscription_cycle === 1
      );
      return monthlyPlan;
    }
    return null;
  };

  const calculatePercentageOff = (
    originalPrice: number,
    currentPrice: number
  ): number => {
    if (originalPrice <= 0) return 0; // Prevent division by zero or invalid input
    return ((originalPrice - currentPrice) / originalPrice) * 100;
  };

  const getCorrespondingPromoRibbonLabel = (plan: TPlanList) => {
    if (isYearly) {
      switch (plan.level) {
        case 1:
          return `${calculatePercentageOff(
            selectedPlan?.prices?.[0]?.original_price ?? 0,
            selectedPlan?.prices?.[0]?.price ?? 0
          ).toFixed(0)}% off`;
        case 2:
          return `${calculatePercentageOff(
            selectedPlan?.prices?.[0]?.original_price ?? 0,
            selectedPlan?.prices?.[0]?.price ?? 0
          ).toFixed(0)}% off`;
        case 3:
          return enableHolidayPromo
            ? `${calculatePercentageOff(
                selectedPlan?.prices?.[0]?.original_price ?? 0,
                (selectedPlan?.prices?.[0]?.price ?? 0) *
                  (1 - HOLIDAY_DISCOUNT_PERCENTAGE / 100)
              ).toFixed(0)}% off`
            : `${calculatePercentageOff(
                selectedPlan?.prices?.[0]?.original_price ?? 0,
                selectedPlan?.prices?.[0]?.price ?? 0
              ).toFixed(0)}% off`;
        default:
          return '';
      }
    } else if (!isYearly && plan.level === 3) {
      return 'PRIME';
    }
    return '';
  };

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={1}
        my={3}
        position="relative"
        ref={planContainerRef}
        width="100%"
      >
        <PricingButton
          variant="contained"
          color="inherit"
          isYearly={isYearly}
          onClick={() => handleToggleYearlyMonthly(false)}
        >
          <Box>
            <Typography fontWeight={600} fontSize={16}>
              {formatPrice(
                getCorrespondingMonthlyPlan(selectedPlan)?.prices?.[0]?.price,
                getCorrespondingMonthlyPlan(selectedPlan)?.prices?.[0]?.currency
              )}
              /mo
            </Typography>
          </Box>
          <Box>
            <Typography fontSize={14}>billed monthly</Typography>
          </Box>
        </PricingButton>
        <Button
          variant="contained"
          color="inherit"
          sx={{
            width: '50%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            color: isYearly ? 'rgba(255, 255, 255, 1)' : 'rgba(51, 51, 51, 1)',
            fontSize: '16px',
            padding: '12px 4px 12px 12px',
            whiteSpace: 'nowrap', // Prevent wrapping
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            borderRadius: '10px',
            background: isYearly
              ? 'linear-gradient(180deg, #4389E1 0%, #5496FF 50%, #185AAD 100%)'
              : 'linear-gradient(95.72deg, #FFFFFF 5.26%, #DEDEDE 98.93%)',
            boxShadow: isYearly
              ? '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
              : '0px 0px 4px 0px rgba(0, 0, 0, 0.21)',
            border: isYearly ? 'none' : '0.5px solid',

            borderImageSource:
              'linear-gradient(104.96deg, #E7E7E7 2.27%, #FFFFFF 60.54%)',

            textTransform: 'none'
          }}
          onClick={() => {
            handleToggleYearlyMonthly(true);
          }}
          onTouchStart={() => handleToggleYearlyMonthly(true)} // Safari iOS compatibility
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 0.5
            }}
          >
            <Typography fontWeight={600} fontSize={16}>
              {formatPrice(
                (selectedPlan?.level === 3 && enableHolidayPromo
                  ? (getCorrespondingYearlyPlan?.prices?.[0]?.price || 0) *
                    (1 - HOLIDAY_DISCOUNT_PERCENTAGE / 100)
                  : getCorrespondingYearlyPlan?.prices?.[0]?.price || 0) / 12,
                getCorrespondingYearlyPlan?.prices?.[0]?.currency
              )}{' '}
              /mo
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '5px',
                padding: '2px 2px',
                background: 'rgba(255, 255, 255, 0.7)',
                boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.1)'
              }}
            >
              <p
                style={{
                  textTransform: 'uppercase',
                  fontSize: '8px',
                  margin: 0,
                  color: 'transparent',
                  background:
                    'linear-gradient(91.65deg, #6FAFFF 4.46%, #13519E 86.98%)',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text'
                }}
              >
                Save{' '}
                {enableHolidayPromo && selectedPlan?.level === 3
                  ? `${calculatePercentageOff(
                      getCorrespondingYearlyPlan?.prices?.[0]?.original_price ??
                        0,
                      (getCorrespondingYearlyPlan?.prices?.[0]?.price ?? 0) *
                        (1 - HOLIDAY_DISCOUNT_PERCENTAGE / 100)
                    ).toFixed(0)}%`
                  : `${calculatePercentageOff(
                      getCorrespondingYearlyPlan?.prices?.[0]?.original_price ??
                        0,
                      getCorrespondingYearlyPlan?.prices?.[0]?.price ?? 0
                    ).toFixed(0)}%`}
              </p>

              <p
                style={{
                  fontSize: '8px',
                  margin: 0
                }}
              >
                🔥
              </p>
            </Box>
          </Box>
          <Box>
            <Typography fontSize={14} color={isYearly ? '#ffffff' : 'black'}>
              {formatPrice(
                selectedPlan?.level === 3 && enableHolidayPromo
                  ? (getCorrespondingYearlyPlan?.prices?.[0].price || 0) *
                      (1 - HOLIDAY_DISCOUNT_PERCENTAGE / 100)
                  : getCorrespondingYearlyPlan?.prices?.[0].price,
                getCorrespondingYearlyPlan?.prices?.[0]?.currency
              )}{' '}
              billed yearly
            </Typography>
          </Box>
        </Button>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '50%',
            background: 'white',
            color: 'rgba(133, 133, 133, 1)',
            padding: '6px 6px',
            border:
              '2px solid linear-gradient(142.22deg, #B6D6FF 10.15%, #4D97F5 85.3%)',
            // borderImageSource:
            //   'linear-gradient(142.22deg, #B6D6FF 10.15%, #4D97F5 85.3%)',
            boxShadow: '0px 0px 0px 2px rgba(127, 184, 255, 1)'
          }}
        >
          <Typography fontSize="12px" fontWeight={500} textAlign="center">
            OR
          </Typography>
        </Box>
      </Stack>
      <Grid container spacing={2}>
        {plansData.map((plan, planIndex) => (
          <Grid item xs={4} key={plan.id}>
            <PlanCard
              selected={selectedPlan?.id === plan.id}
              planIndex={planIndex}
              // onPointerDown={() => handleRadioChange(plan, index)} // Additional fallback for pointer events
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '4px'
                }}
                onClick={() => {
                  handleRadioChange(plan, planIndex);
                }} // Main click handler
                onTouchStart={() => handleRadioChange(plan, planIndex)} // Safari iOS compatibility
              >
                {getCorrespondingPromoRibbonLabel(plan) && (
                  <PromoRibbon
                    label={getCorrespondingPromoRibbonLabel(plan)}
                    styles={{
                      position: 'absolute',
                      top: 0,
                      left: 0
                    }}
                  />
                )}

                <Box sx={{ position: 'absolute', top: 5, right: 1 }}>
                  <Radio
                    value={plan.id}
                    checked={selectedPlan?.id === plan.id}
                    disabled={filterByLevel && !filterByLevel(plan)}
                    disableRipple
                    onChange={(e) => {
                      e.stopPropagation();
                      handleRadioChange(plan, planIndex);
                    }}
                    sx={{
                      background: 'transparent',
                      transform: 'scale(1.1)', // Slightly increase the size
                      '& .MuiSvgIcon-root': {
                        boxShadow: '2px 4px 2px rgba(0, 0, 0, 0.1)', // Add shadow to the inner circle
                        borderRadius: '50%' // Ensure the shadow aligns with the circular shape
                      },
                      '&.Mui-checked .MuiSvgIcon-root': {
                        boxShadow: '0px 4px 4px rgba(124, 120, 120, 0.2)' // Shadow for checked state
                      }
                    }}
                    data-testid={`plan-radio-${planIndex}`}
                  />
                </Box>

                <Box sx={{ maxWidth: '30px' }}>
                  <img
                    src={planIcons[planIndex]}
                    alt="Plan Icon"
                    style={{ width: '100%', height: 'auto' }}
                  />
                </Box>
                <Typography variant="h4" gutterBottom fontWeight="bold">
                  {plan?.name?.replace('Security', '')}
                </Typography>
                <Typography variant="h3" component="div" gutterBottom>
                  {/* For Premium yearly plan we are giving 20% more discount holiday promo */}
                  {isYearly
                    ? enableHolidayPromo && plan.level === 3
                      ? `${formatPrice(
                          ((plan?.prices[0].price || 0) / 12) *
                            (1 - HOLIDAY_DISCOUNT_PERCENTAGE / 100),
                          plan?.prices[0].currency
                        )}/mo`
                      : `${formatPrice(
                          (plan?.prices[0].price || 0) / 12,
                          plan?.prices[0].currency
                        )}/mo`
                    : `$${(plan.prices[0].price / 100).toFixed(2)}`}
                </Typography>
                <Typography
                  textAlign="center"
                  variant="body2"
                  gutterBottom={false}
                  lineHeight="18px"
                >
                  {/* For Premium yearly plan we are giving 20% more discount holiday promo, therefore multiplied by 0.8 */}

                  {isYearly
                    ? enableHolidayPromo && plan.level === 3
                      ? `${formatPrice(
                          (plan?.prices[0].price || 0) *
                            (1 - HOLIDAY_DISCOUNT_PERCENTAGE / 100),
                          plan?.prices[0].currency
                        )} billed yearly`
                      : `$${(plan.prices[0].price / 100).toFixed(
                          2
                        )} billed yearly`
                    : 'billed monthly'}
                </Typography>
              </Box>
              {/* Feature Icons */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 5
                }}
              >
                {uniqueFeatures.map((feature) => (
                  <FeatureContainer
                    key={feature}
                    planIndex={planIndex}
                    feature={feature}
                    containerWidth={planContainerWidth}
                  >
                    {plan.description.includes(feature) ? (
                      <CheckIcon sx={{ color: 'rgba(54, 191, 39, 1)' }} />
                    ) : (
                      <CloseIcon sx={{ color: 'rgba(242, 40, 42, 1)' }} />
                    )}
                  </FeatureContainer>
                ))}
                <FeatureContainer
                  planIndex={planIndex}
                  feature="Number of cameras supported"
                  containerWidth={planContainerWidth}
                >
                  {getSupportedCameraCount(plan.supported_features)}
                </FeatureContainer>
              </Box>
            </PlanCard>
          </Grid>
        ))}
      </Grid>
      {allowSelection && (
        <Box>
          <Box
            sx={{
              textAlign: 'center',
              margin: 0
            }}
          >
            <FormControlLabel
              sx={{
                fontSize: '14px',
                '& .MuiFormControlLabel-label': {
                  fontSize: '14px'
                },
                textDecoration: 'underline',
                color: 'primary.main',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginTop: 1,
                marginBottom: 1,
                textAlign: 'left'
              }}
              disabled={!selectedPlan || checkUpgradeEligibility}
              control={
                <Checkbox
                  checked={isCancellationAndRefundPolicyAccepted}
                  onChange={(e) => {
                    setIsCancellationAndRefundPolicyAccepted(e.target.checked);
                    setSearchParams(
                      (prev) => {
                        prev.set(
                          'acceptedCancellationAndRefundPolicy',
                          String(e.target.checked)
                        );
                        return prev;
                      },
                      {
                        state: {
                          ...location.state
                        }
                      }
                    );
                  }}
                  sx={{
                    color: 'text.shadedText',
                    '&.Mui-checked': {
                      color:
                        !selectedPlan || checkUpgradeEligibility
                          ? 'text.shadedText'
                          : 'primary.main'
                    },
                    margin: 0
                  }}
                  size="medium"
                />
              }
              label={
                <Link
                  to="/cancellation-refund-policy"
                  state={{
                    from: location.pathname,
                    ...location.state
                  }}
                  style={{ color: '#4389e1', fontSize: '0.852rem', margin: 0 }}
                >
                  {t('agreeToRefundAndCancellationPolicy', {
                    companyName: config.nameOfCompany
                  })}
                </Link>
              }
            />
          </Box>
          <InstavisionButton
            onClick={() => {
              if (!isMobile || (isMobile && !isIOS)) {
                if (onPlanSelection && selectedPlan) {
                  onPlanSelection(selectedPlan);
                }
              }
            }}
            onTouchStart={() => {
              if (isMobile && isIOS) {
                if (onPlanSelection && selectedPlan) {
                  onPlanSelection(selectedPlan);
                }
              }
            }}
            disabled={
              !selectedPlan ||
              checkUpgradeEligibility ||
              !isCancellationAndRefundPolicyAccepted
            }
            sx={{
              margin: '0',
              marginBottom: '20px',
              width: '100%',
              transition: 'box-shadow 0.1s ease',
              fontSize: '1.3rem',
              textTransform: 'capitalize'
            }}
            disableRipple
          >
            {!isUpgrade && isFreeTrial && <>{t('tryForFree')}</>}
            {!isUpgrade && !isFreeTrial && <>{t('continue')}</>}
            {isUpgrade && <>{t('continueToUpgrade')}</>}
          </InstavisionButton>
        </Box>
      )}

      {/* <InformationContainer>
        {isFreeTrial && (
          <InformationText>{t('freeCancellation')}</InformationText>
        )}
        <InformationText>{t('turnOffAutoRenewal')}</InformationText>
      </InformationContainer> */}
    </Box>
  );
}

export default PlanComparisonComponent;
