import InternetNotConnected from 'components/InternetNotConnection/InternetNotConnected';
import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

function InternetStatusRoute() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const updateOnlineStatus = () => setIsOnline(navigator.onLine);

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  if (!isOnline) {
    return <InternetNotConnected />;
  }

  return <Outlet />;
}

export default InternetStatusRoute;
