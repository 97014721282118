import { Stack } from '@mui/material';
import InstaSpinner from 'components/InstaSpinner';
import { isRunningInWebView } from 'utils/webviewHelpers';

function InstaLoader() {
  const isWebViewPresent = isRunningInWebView();

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      height={isWebViewPresent ? '100vh' : '80%'}
      maxHeight="100vh"
    >
      <InstaSpinner />
    </Stack>
  );
}

export default InstaLoader;
