/* eslint-disable @typescript-eslint/no-explicit-any */
import { Stack, Typography } from '@mui/material';
import InstaSpinner from 'components/InstaSpinner';
import FreeTrialActivatedPopup from 'components/PopUps/FreeTrialActivatedPopup/FreeTrialActivatedPopup';
import PaymentFailedPopup from 'components/PopUps/PaymentFailedPopup/PaymentFailedPopup';
import PaymentConfirmedPopup from 'components/PopUps/PaymentSuccessPopup/paymentSucessPopups';
import PopUp from 'coreComponents/PopUp/PopUp';
import { homeSecurityPortalApi } from 'data/axios';
import { AppBridge } from 'infrastructure/appBridge/appBridge';
import { APP_BRIDGE_CALLBACKS } from 'infrastructure/appBridge/appCallBacks';
import analyticsService, {
  AppEvent
} from 'infrastructure/serviceHandlers/Analytics/Analytics';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { endpoints } from 'services/api/endpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchAllSubscription } from 'store/reducers/subscriptionReducer';
import {
  SUBSCRIPTION_TYPE,
  TDeviceResponseType,
  TSubscriptionType
} from 'typings';
import { PopupTypes } from 'typings/popUpTypes';
import { getLocalStorageItem } from 'utils/commonFunctions';
import { getPlanDetails } from 'utils/subscriptionFunctions';
import { hideAppButton, isRunningInWebView } from 'utils/webviewHelpers';

function PaymentSuccess() {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pollingCount, setPollingCount] = useState(0);
  const [purchaseStatus, setPurchaseStatus] = useState<boolean>(false);
  const [subscriptionType, seSubscriptionType] = useState<SUBSCRIPTION_TYPE>();
  const [selectedSpaceDeviceData, setSelectedSpaceDeviceData] = useState<
    TDeviceResponseType[] | null
  >(null);
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.subscription.paymentStatus'
  });
  const [paymentFailed, setPaymentFailed] = useState<boolean>(false);

  const subscriptionData = useAppSelector<TSubscriptionType[]>(
    (state) => state.subscription.subscriptionData
  );

  const { selectedSubscriptionInfo } = useAppSelector(
    (state) => state.subscription
  );

  const spaceData = useAppSelector((state) => state.space.spaceData);
  const params = new URLSearchParams(search);
  const purchaseId = params.get('purchase_id');
  const userId = getLocalStorageItem('userId');

  // console.log('Re-rendering PaymentSuccess with paymentFailed:', paymentFailed);

  console.log('selected space devices', selectedSpaceDeviceData);

  const findSubscriptionByPurchaseId = (subscriptions: any) => {
    const found = subscriptions.filter((item: any) => item.id === purchaseId);
    return found.length ? found[0] : false;
  };

  const handleDeviceAdditionDuringNormalFlow = (
    deviceArray: string[],
    cameraCountSupportedByPurchasedPlan: number
  ) => {
    let payload = {};

    console.log('deviceArray', deviceArray);
    console.log('camera count supported ', cameraCountSupportedByPurchasedPlan);
    if (deviceArray.length <= cameraCountSupportedByPurchasedPlan) {
      payload = { device_ids: deviceArray };
    } else {
      payload = {
        device_ids: deviceArray.slice(0, cameraCountSupportedByPurchasedPlan)
      };
    }
    return payload;
  };

  const triggerAnalyticsEvent = (subscriptionObj: TSubscriptionType) => {
    console.log(
      'Triggering amplitude event',
      subscriptionObj,
      selectedSubscriptionInfo
    );
    if (subscriptionObj.subscription_type === SUBSCRIPTION_TYPE.TRIAL) {
      analyticsService.trackEvent(AppEvent.TRIAL_CONFIRMED, {
        user_id: userId,
        level_cycle: getPlanDetails(
          selectedSubscriptionInfo?.planSelected?.level,
          subscriptionObj?.subscription_cycle
        ),
        ...(analyticsService.getUserPathFromLocalStorage() && {
          user_path: analyticsService.getUserPathFromLocalStorage()
        })
      });
    } else if (subscriptionObj.subscription_type === SUBSCRIPTION_TYPE.PAID) {
      analyticsService.trackEvent(AppEvent.SUBSCRIPTION_PURCHASE_SUCCESS, {
        level_cycle: getPlanDetails(
          selectedSubscriptionInfo?.planSelected?.level,
          subscriptionObj?.subscription_cycle
        ),
        user_id: userId,
        ...(analyticsService.getUserPathFromLocalStorage() && {
          user_path: analyticsService.getUserPathFromLocalStorage()
        })
      });
    }
  };

  const addCameraToSubscription = async (
    subscriptionObj: TSubscriptionType
  ) => {
    const deviceArray = selectedSpaceDeviceData?.map((el) => el?.id);
    if (deviceArray) {
      if (deviceArray && deviceArray.length === 0) {
        // if there are no devices, we dont need to add any device to subscription
        setPurchaseStatus(true);
        setIsLoading(false);
        return;
      }
      console.log('device Array selected space', selectedSpaceDeviceData);
      console.log('selectedSpaceDeviceData', selectedSpaceDeviceData);
      const camerasSupportedFeature = subscriptionObj.supported_features.find(
        (feature) => feature.id === 'cameras_supported'
      );
      const cameraCountSupportedByPurchasedPlan =
        camerasSupportedFeature && camerasSupportedFeature?.value
          ? parseInt(camerasSupportedFeature?.value, 10)
          : 0;

      const payload = handleDeviceAdditionDuringNormalFlow(
        deviceArray,
        cameraCountSupportedByPurchasedPlan
      );
      console.log('payload', payload);

      homeSecurityPortalApi
        .put(endpoints.addDevice(subscriptionObj.id), payload)
        .then((res) => {
          console.log('addCameraToSubscription SUCCESS:', res);
          dispatch(fetchAllSubscription());
          if (isRunningInWebView()) {
            console.log('calling app for subscription upgrade');
            AppBridge.sendMessageToApp(
              APP_BRIDGE_CALLBACKS.UPDATED_SUBSCRIPTION,
              true
            );
          }
        })
        .catch((err) => {
          console.log('addCameraToSubscription ERROR:', err);
        })
        .finally(() => {
          setPurchaseStatus(true);
          setIsLoading(false);
        });
    }
  };

  const polling = () => {
    console.log('Polling function executed');

    setTimeout(() => {
      setPollingCount((prevCount) => {
        const nextCount = prevCount + 1;

        if (nextCount >= 10) {
          console.log(
            'Polling attempts exhausted. Setting paymentFailed to true.'
          );
          setPurchaseStatus(false);
          setPaymentFailed(true);
          setIsLoading(false);

          analyticsService.trackEvent(
            selectedSubscriptionInfo.isFreeTrial
              ? AppEvent.TRIAL_PURCHASE_FAILED
              : AppEvent.SUBSCRIPTION_PURCHASE_FAILURE,
            {
              user_id: userId,
              purchase_id: purchaseId,
              reason: 'polling_timeout',
              ...(analyticsService.getUserPathFromLocalStorage() && {
                user_path: analyticsService.getUserPathFromLocalStorage()
              })
            }
          );

          return nextCount;
        }

        dispatch(fetchAllSubscription())
          .unwrap()
          .then((res) => {
            const found = findSubscriptionByPurchaseId(res);
            console.log('Found subscription:', found);
            if (found && found.id === purchaseId) {
              try {
                triggerAnalyticsEvent(found);
                seSubscriptionType(found.subscription_type);
                addCameraToSubscription(found);
              } catch (error) {
                console.error('Analytics event failed:', error);
                setTimeout(() => triggerAnalyticsEvent(found), 1000);
              }
            } else {
              console.log('pollingCount', nextCount, Date.now());
              polling();
            }
          })
          .catch((err: unknown) => {
            console.log('fetchAllSubscription ERROR:', err);
            polling();
          });

        return nextCount;
      });
    }, 2000);
  };

  const fetchAllDevices = () => {
    homeSecurityPortalApi
      .get(endpoints.getAllDeviceBySpaceIdUrl(spaceData[0]?.id || ''))
      .then((res) => {
        console.log('fetchAllDevices Res:', res);
        setSelectedSpaceDeviceData(res.data.items || []);
      })
      .catch((err) => {
        console.log('fetchAllDevices ERROR:', err);
      });
  };

  useEffect(() => {
    fetchAllDevices();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    console.log('Use Effect Selected device data', selectedSpaceDeviceData);
    if (selectedSpaceDeviceData && selectedSpaceDeviceData !== null) {
      polling();
    }
  }, [selectedSpaceDeviceData]);

  useEffect(() => {
    if (isRunningInWebView()) {
      hideAppButton();
    }
  }, [isRunningInWebView]);

  if (isLoading)
    return (
      <PopUp isOpen type={PopupTypes.TYPE2}>
        <Stack justifyContent="center" alignItems="center">
          <InstaSpinner />
          <Typography variant="h3" mt={4} textAlign="center">
            {t('pleaseWait')}...
          </Typography>
        </Stack>
      </PopUp>
    );

  return (
    <div>
      {purchaseStatus && subscriptionType !== SUBSCRIPTION_TYPE.TRIAL && (
        <PaymentConfirmedPopup
          planCycle={subscriptionData[0]?.subscription_cycle}
          planName={subscriptionData[0]?.sku_name}
        />
      )}
      {purchaseStatus && subscriptionType === SUBSCRIPTION_TYPE.TRIAL && (
        <FreeTrialActivatedPopup isUpgrade={false} />
      )}

      {paymentFailed && <PaymentFailedPopup />}
    </div>
  );
}

export default PaymentSuccess;
