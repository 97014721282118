export function getPlanDetails(
  planLevel: number,
  subscriptionCycle: number
): string {
  // Determine the plan type based on the subscription cycle
  let planType: string;
  if (subscriptionCycle === 1) {
    planType = 'monthly';
  } else if (subscriptionCycle === 12) {
    planType = 'yearly';
  } else {
    planType = '';
  }
  return `${planType}_${planLevel}`;
}
