import { Skeleton, Stack } from '@mui/material';

function PlanPageLoader({ canBuyPlan }: { canBuyPlan: boolean }) {
  return (
    <Stack>
      <Skeleton variant="rectangular" height="52px" animation="pulse" />
      <Skeleton
        variant="rounded"
        sx={{
          marginTop: '24px',
          marginBottom: '24px'
        }}
        height="70px"
        animation="pulse"
      />

      <Skeleton
        variant="rounded"
        sx={{
          borderRadius: '12px',
          marginBottom: 3
        }}
        height={620}
        animation="pulse"
      />
      {canBuyPlan && (
        <Skeleton
          variant="rounded"
          sx={{
            marginBottom: 2,
            borderRadius: '30px'
          }}
          height="62px"
          animation="pulse"
        />
      )}
    </Stack>
  );
}

export default PlanPageLoader;
