/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/control-has-associated-label */
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box } from '@mui/material';
import Popover, { PopoverProps } from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import * as React from 'react';

const InstavisionButton = styled('button')(() => ({
  textAlign: 'center',
  boxShadow: 'none',
  border: 'none',
  outline: 'none',
  background: 'transparent',
  '&:focus': {
    outline: 'none'
  }
}));

type RenderFunction = (props: { handleClose: () => void }) => React.ReactNode;

export interface MenuPopupProps extends Partial<PopoverProps> {
  disablePopperButton?: boolean;
  render: React.ReactNode | RenderFunction;
}

export default function MenuPopup(props: MenuPopupProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { disablePopperButton = false, render, ...restOfProps } = props;
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <InstavisionButton
        type="button"
        onClick={handleClick}
        disabled={disablePopperButton}
      >
        <MoreVertIcon />
      </InstavisionButton>
      <Popover
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restOfProps}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        anchorEl={anchorEl}
        id={id}
        onClose={handleClose}
      >
        <Box
          sx={{
            background: '#EDEDED',
            borderRadius: '4px',
            padding: '8px',
            cursor: 'pointer'
          }}
        >
          {typeof render === 'function' ? render({ handleClose }) : render}
        </Box>
      </Popover>
    </>
  );
}
