/* eslint-disable @typescript-eslint/no-explicit-any */
interface IAppBridge {
  sendMessageToApp(method: string, data?: unknown): void;
  sendMessageToAppWithReturn(method: string, data: unknown): Promise<unknown>;
}

export const AppBridge: IAppBridge = {
  sendMessageToApp(method: string, data?: unknown): void {
    const message = { data };
    // iOS Communication
    if (window.webkit?.messageHandlers[method]) {
      window.webkit.messageHandlers[method].postMessage(
        JSON.stringify(message)
      );
    }
    // Android Communication
    else if (window.Android && typeof window.Android[method] === 'function') {
      window.Android[method]();
    }
  },

  sendMessageToAppWithReturn(method: string, data: unknown): Promise<unknown> {
    return new Promise((resolve, reject) => {
      const message = { data };
      if (window.webkit?.messageHandlers[method]) {
        window.webkit.messageHandlers[method].postMessage(
          JSON.stringify(message)
        );
        (window as any)[`${method}Callback`] = resolve;
      } else if (
        window.Android &&
        typeof window.Android[method] === 'function'
      ) {
        const result = window.Android[method](JSON.stringify(message));
        resolve(result);
      } else {
        reject(new Error('Method not available'));
      }
    });
  }
};

// // Global handlers need to be registered directly on window
// (window as any).receiveMessageFromAppMethodOne = (response: {
//   data: unknown;
// }) => {
//   console.log('Received from App (String):', response.data);
// };

// (window as any).receiveMessageFromAppMethodTwo = (response: {
//   data: boolean;
// }) => {
//   console.log('Received from App (Bool):', response.data);
// };

// (window as any).receiveMessageFromAppMethodThree = (response: {
//   data: Record<string, unknown>;
// }) => {
//   console.log('Received from App (Json):', response.data);
// };
