/* eslint-disable import/no-extraneous-dependencies */
import { Stripe, StripeElementsOptions } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { TFunction } from 'i18next';
import envConfig from './envConfig';

loadStripe.setLoadParameters({ advancedFraudSignals: false });

const stripeKey = envConfig('stripeKey') || '';
if (!stripeKey) {
  console.log('Stripe is not configured');
}

// eslint-disable-next-line import/no-mutable-exports
let stripePromise: Promise<Stripe | null> | null = null;

// Function to initialize Stripe to avoid top-level await
async function initializeStripe(): Promise<Stripe | null> {
  if (!stripeKey) {
    console.warn('No Stripe Key available');
    return null;
  }
  if (!stripePromise) {
    stripePromise = loadStripe(stripeKey);
  }
  return stripePromise;
}

const stripeOptions: StripeElementsOptions = {
  clientSecret: '',
  appearance: {
    theme: 'stripe',
    labels: 'above',
    variables: {
      fontFamily: 'Nunito Sans, sans-serif',
      fontSizeBase: '18px',
      fontWeightNormal: '500',
      borderRadius: '20px'
    },
    rules: {
      // '.Input, .Block': {
      //   backgroundColor: 'transparent',
      //   border: '1.5px solid rgb(184, 187, 187)',
      //   borderRadius: '25px'
      // },
      '.Input, .Block': {
        backgroundColor: 'transparent',
        border: '1.5px solid #D5D5D5',
        borderRadius: '16px',
        padding: '12px'
      },
      '.DropdownItem': {
        backgroundColor: '#F1F1F1'
      },
      '.DropdownItem--highlight': {
        backgroundColor: '#EDEDED'
      }
    }
  }
};

const errorCodes = {
  GENERIC_DECLINE: 'generic_decline'
};

const getStripeErrorMessages = (
  t: TFunction<'translation', 'views.paymentScreen'>
) => {
  return {
    ADDRESS_MISMATCH: t('addressMismatchError'),
    NO_PREPAID_CARDS: t('noPrepaidCardError')
  };
};

export { errorCodes, getStripeErrorMessages, initializeStripe, stripeOptions };
