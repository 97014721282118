import { AppBridge } from 'infrastructure/appBridge/appBridge';
import { APP_BRIDGE_CALLBACKS } from 'infrastructure/appBridge/appCallBacks';

export const isRunningInWebView = () => {
  // Native bridge checks
  const hasAndroidBridge = !!window.Android;
  const hasIOSBridge = !!window.webkit && !!window.webkit.messageHandlers;

  // // Get user agent
  const ua = navigator.userAgent;

  // // Specific browser identifications
  const isIOSChrome = /CriOS\/[0-9]/.test(ua);
  // const isStandardChrome =
  //   /Chrome\/[0-9]/.test(ua) && !(/Android/.test(ua) && /wv/.test(ua));
  // const isSafari =
  //   /Safari\/[0-9]/.test(ua) &&
  //   !/CriOS/.test(ua) &&
  //   !/FxiOS/.test(ua) &&
  //   !/EdgiOS/.test(ua);
  // const isFirefox = /Firefox\/[0-9]/.test(ua) || /FxiOS\/[0-9]/.test(ua);
  // const isEdge = /Edge\/[0-9]/.test(ua) || /EdgiOS\/[0-9]/.test(ua);
  // const isSamsungBrowser = /SamsungBrowser\/[0-9]/.test(ua);

  // // Combined browser check
  // const isStandardBrowser =
  //   isIOSChrome ||
  //   isStandardChrome ||
  //   isSafari ||
  //   isFirefox ||
  //   isEdge ||
  //   isSamsungBrowser;

  // // WebView specific patterns
  // const hasWebViewMarker =
  //   /wv/.test(ua) ||
  //   /FBAN/.test(ua) ||
  //   /FBAV/.test(ua) ||
  //   /Instagram/.test(ua) ||
  //   /Line\//.test(ua);

  // // Android specific WebView checks
  // const isAndroidWebView =
  //   /Android/.test(ua) &&
  //   (hasWebViewMarker ||
  //     hasAndroidBridge ||
  //     (/Android/.test(ua) && /Version\/[0-9]/.test(ua) && !isSamsungBrowser));

  // // iOS specific WebView checks
  // const isIOSWebView =
  //   /iPhone|iPad|iPod/.test(ua) &&
  //   (hasIOSBridge ||
  //     (/AppleWebKit/.test(ua) &&
  //       !isSafari &&
  //       !isIOSChrome &&
  //       !/FxiOS/.test(ua) &&
  //       !/EdgiOS/.test(ua)));

  // return (
  //   (isAndroidWebView || isIOSWebView || hasWebViewMarker) && !isStandardBrowser
  // );
  return (hasAndroidBridge || hasIOSBridge) && !isIOSChrome;
};

export const APP_BUTTON_TYPES = {
  CLOSE: 'close',
  RELOAD: 'reload',
  BACK: 'back'
} as const;

type AppButtonType = (typeof APP_BUTTON_TYPES)[keyof typeof APP_BUTTON_TYPES];

export const showAppButton = (...buttonsToShow: AppButtonType[]) => {
  try {
    console.log('showing buttons');
    if (!buttonsToShow.includes('close')) {
      console.log('not close buttons');
      AppBridge.sendMessageToApp(APP_BRIDGE_CALLBACKS.HIDE_CLOSE_BUTTON);
    } else {
      console.log('close buttons');
      AppBridge.sendMessageToApp(APP_BRIDGE_CALLBACKS.SHOW_CLOSE_BUTTON);
    }

    if (!buttonsToShow.includes('reload')) {
      console.log('not reload buttons');
      AppBridge.sendMessageToApp(APP_BRIDGE_CALLBACKS.HIDE_RELOAD_BUTTON);
    } else {
      console.log('reload buttons');
      AppBridge.sendMessageToApp(APP_BRIDGE_CALLBACKS.SHOW_RELOAD_BUTTON);
    }

    if (!buttonsToShow.includes('back')) {
      console.log('not back buttons');
      AppBridge.sendMessageToApp(APP_BRIDGE_CALLBACKS.HIDE_BACK_BUTTON);
    } else {
      console.log('back buttons');
      AppBridge.sendMessageToApp(APP_BRIDGE_CALLBACKS.SHOW_BACK_BUTTON);
    }
  } catch (err) {
    console.error('Error while showing app buttons', err);
  }
};

export const hideAppButton = () => {
  try {
    console.log('hiding buttons');
    AppBridge.sendMessageToApp(APP_BRIDGE_CALLBACKS.HIDE_CLOSE_BUTTON);
    AppBridge.sendMessageToApp(APP_BRIDGE_CALLBACKS.HIDE_RELOAD_BUTTON);
    AppBridge.sendMessageToApp(APP_BRIDGE_CALLBACKS.HIDE_BACK_BUTTON);
  } catch (err) {
    console.error('Error while hiding app buttons', err);
  }
};

export const isAndroidWebView = !!window.Android;

export const isIOSWebView = !!window.webkit && !!window.webkit.messageHandlers;

// Fetch Access Token

export const fetchWebViewAccessToken = async (
  needRefresh: boolean = false
): Promise<string> => {
  try {
    const res = await AppBridge.sendMessageToAppWithReturn(
      APP_BRIDGE_CALLBACKS.GET_ACCESS_TOKEN,
      {
        needRefresh
      }
    );
    if (isAndroidWebView) {
      console.log('Android webview detected');
      return JSON.parse(res as string)?.accessToken;
    }
    if (isIOSWebView) {
      console.log('iOS webview detected');
      return JSON.parse(JSON.stringify(res))?.accessToken;
    }
    return JSON.parse(res as string)?.accessToken;
  } catch (err) {
    console.error('Error fetching access token', err);
    throw new Error('Error fetching access token');
  }
};
